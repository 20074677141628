
import {
  CourseSection,
  HomeHero,
  OfferSection,
  SecureSection,
  StudentSays,
  SecureSectionMobile,
  Taskbar,
} from "../components/home";


function Home() {
  return (
    <>
      <HomeHero />
      <OfferSection />
      <CourseSection />
      <SecureSection/>
      <StudentSays/>
      <SecureSectionMobile/>
      <Taskbar/>

    </>
  );
}

export default Home;
