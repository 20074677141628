import React from 'react'




const Image = () => {
  return (
    <div  className="bg-lady_img  w-full h-[20rem] md:h-[50rem]  bg-cover bg-center mb-10 flex text-center">
      <div className=" text-[#fff] font-[montserrat] m-auto space-y-2">
      <h1 className=" font-bold text-[20px] md:text-[60px]">Courses</h1>
            <p className='font-medium text-sm md:text-[20px]' >Free learning materials for your courses</p>
      </div>
      
    </div>
  )
}

export default Image

