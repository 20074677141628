import React from 'react'

const DashInfo = ({numberOfCourses}) => {
	return (
		<div className="bg-[#08132A] border-[2px] border-[#0066FF] rounded-[25px] p-8 m-4 md:m-12">
			<div className="text-white flex flex-col md:flex-row justify-between items-center">
				<div>
					<h2 className="text-xl md:text-2xl font-Montserrat font-bold">Welcome back, Tobiloba</h2>
					<p className="font-inter text-xs md:pt-4">Bachelor of Science in Electrical Engineering</p>
				</div>
				<ul className="flex justify-end items-center gap-6 pt-8 md:pt-0">
					<li className="flex flex-col justify-center items-center gap-2">
						<p className="text-[#F2F4F7] text-xs font-medium font-Montserrat">Level</p>
						<p className="text-white text-xs font-semibold font-Montserrat">5</p>
					</li>
					<li className="flex flex-col justify-center items-center gap-2">
						<p className="text-[#F2F4F7] text-xs font-medium font-Montserrat">Courses</p>
						<p className="text-white text-xs font-semibold font-Montserrat">{numberOfCourses}</p>
					</li>
					<li className="flex flex-col justify-center items-center gap-2">
						<p className="text-[#F2F4F7] text-xs font-medium font-Montserrat">Downloads</p>
						<p className="text-white text-xs font-semibold font-Montserrat">0</p>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default DashInfo