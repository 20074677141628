import React from 'react'
import Logo from '../../assets/images/Logo.png';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import {useDispatch, useSelector} from 'react-redux';
import {sideBarActions} from '../../store/sideBarSlice';

const MobileHeader = () => {
	const openMobile = useSelector(state => state.sideBar.openMobile)
	const dispatch = useDispatch()
	const handleOpenMobile = () => {
		dispatch(sideBarActions.setOpenMobiletrue());
	}
	const handleCloseMobile = () => {
		dispatch(sideBarActions.setOpenMobilefalse());
	}

	return (
		<div className="md:hidden flex justify-between items-center py-2 pr-6 pl-2">
			<div>
				<img className=" " src={Logo} alt="logo"/>
			</div>
			<div className="md:hidden text-3xl">
				{!openMobile ? <AiOutlineMenuFold onClick={handleOpenMobile}/> : <AiOutlineMenuUnfold onClick={handleCloseMobile} />}
			</div>
		</div>
	)
}

export default MobileHeader