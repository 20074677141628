import React from 'react'
import Box from '../../assets/images/Box.svg'
import Header from '../../components/dashboard/Header'
import MobileHeader from '../../components/dashboard/MobileHeader'


const Courses = () => {
	const courseList = ["GEG 113", "ACC  113", "FSC 112", "GEG 527"]


	return (
		<div>
		    <MobileHeader/>
		    <Header title="Courses"/>
			<div className="p-8 ">
				<div>
					<h2 className="font-Montserrat font-medium text-lg text-black text-center md:text-left">Your saved courses(4)</h2>
					<div className="flex flex-col md:flex-row gap-6 md:gap-0 justify-between items-center flex-wrap pt-4 pb-8 ">
						{
							courseList.map((item,i) =>
								<div key={i} className="rounded-[10px] w-[192px] bg-white shadow" >
									<img src={Box} alt="card"/>
									<div className="p-4 pb-6">
										<p className="text-black text-sm font-Montserrat font-semibold">{item}</p>
										<button className="text-[#0066FF] font-Montserrat font-medium text-xs" >Attempt questions</button>
									</div>
								</div>
							)
						}
					</div>
				</div>
				<div>
					<h2 className="font-Montserrat font-medium text-lg text-black text-center md:text-left">Your faculty courses(4)</h2>
					<div className="flex flex-col md:flex-row gap-6 md:gap-0 justify-between items-center flex-wrap pt-4 pb-8">
						{
							courseList.map((item,i) =>
								<div key={i} className="rounded-[10px] w-[192px] bg-white shadow" >
									<img src={Box} alt="card"/>
									<div className="p-4 pb-6">
										<p className="text-black text-sm font-Montserrat font-semibold">{item}</p>
										<button className="text-[#0066FF] font-Montserrat font-medium text-xs" >Attempt questions</button>
									</div>
								</div>
							)
						}
					</div>
				</div>
				<div>
					<h2 className="font-Montserrat font-medium text-lg text-black text-center md:text-left">Your departmental courses</h2>
					<div className="flex flex-col md:flex-row gap-6 md:gap-0 justify-between items-center flex-wrap pt-4 pb-8">
						{
							courseList.map((item,i) =>
								<div key={i} className="rounded-[10px] w-[192px] bg-white shadow" >
									<img src={Box} alt="card"/>
									<div className="p-4 pb-6">
										<p className="text-black text-sm font-Montserrat font-semibold">{item}</p>
										<button className="text-[#0066FF] font-Montserrat font-medium text-xs" >Attempt questions</button>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Courses