import Ellip1 from "../assets/images/Ellipse1.png";
import Ellip2 from "../assets/images/Ellipse2.png";
import Ellip3 from "../assets/images/Ellipse3.png";
import Ellip5 from "../assets/images/Ellipse5.png";
import Ellip6 from "../assets/images/Ellipse6.png";
import Chide from "../assets/images/chide.png";
import Tunde from "../assets/images/tunde.png";
import Franck from "../assets/images/franck.png";
import Naledi from "../assets/images/naledi.png";
import Tiku from "../assets/images/tiku.png";
import Chart from "../assets/images/chart.png";
import Book from "../assets/images/book.png";
import People from "../assets/images/people.png";

export const reviewData = [
  {
    image: Ellip1,
    name: " Anthony Moradeyo",
    department: " 200L Pyhysics ",
    review:
      "Tutcov has given me the resources I need to accomplish my academic goals and I intend to keep using the platform in my remaining years to come. ",
  },
  {
    image: Ellip2,
    name: " Anthony Moradeyo",
    department: " 200L Pyhysics ",
    review:
      "Tutcov has given me the resources I need to accomplish my academic goals and I intend to keep using the platform in my remaining years to come. ",
  },
  {
    image: Ellip3,
    name: " Anthony Moradeyo",
    department: " 200L Pyhysics ",
    review:
      "Tutcov has given me the resources I need to accomplish my academic goals and I intend to keep using the platform in my remaining years to come. ",
  },
  {
    image: Ellip5,
    name: " Anthony Moradeyo",
    department: " 200L  Pyhysics ",
    review:
      "Tutcov has given me the resources I need to accomplish my academic goals and I intend to keep using the platform in my remaining years to come. ",
  },
  {
    image: Ellip6,
    name: " Anthony Moradeyo",
    department: " 200L Pyhysics ",
    review:
      "Tutcov has given me the resources I need to accomplish my academic goals and I intend to keep using the platform in my remaining years to come. ",
  },
];

export const teamData = [
  {
    image: Naledi,
    name: "Naledi Molefe",
    role: "Tutoring Coordinator",
  },
  {
    image: Tunde,
    name: "Tunde Akinloye",
    role: "CEO/Founder",
  },
  {
    image: Chide,
    name: "Chide Moke",
    role: "Operations Manager",
  },
  {
    image: Tiku,
    name: "Tiku Asante",
    role: "Test Prep Tutor",
  },
  {
    image: Franck,
    name: "Frank Moses",
    role: "Customer Support",
  },
];

export const serviceData = [
  {
    bgIcon: "rgba(158, 119, 237, 0.20)",
    icon: Chart,
    content: "Access to past questions with detailed solutions",
  },
  {
    bgIcon: "rgba(249, 150, 94, 0.20)",
    icon: Book,
    content:
      "Learning aids, study guides, and materials covering essential concepts and topics relevant to your courses",
  },
  {
    bgIcon: "rgba(18, 183, 106, 0.20)",
    icon: People,
    content:
      "Mock exams designed to stimulate the actual test-taking experience as closely as possible",
  },
];

export const faculties = ["science", "law", "engineering", "art"];
export const levels = [100, 200, 300, 400, 500];
