import Math from "../../assets/images/math.png";
import Calculus from "../../assets/images/calculus.png";
import Physics from "../../assets/images/physics.png";



 const CoursesDetails =[
    
{
    id:1,
Img: Math ,
Code: "GEG 117 ",
Title : " Engneering Calculus ",
view:false
},
{
    id:2,
Img: Calculus ,
Code: "GEG 115 ",
 Title : " Engneering Algebra ",
 view:false
 },
            {id:3,
                Img: Physics ,
                Code: "GEG 117 ",
                Title : " Applied Mathematics ",
                view:false
                },
                {    id:4,
                    Img: Math ,
                    Code:"FSC 115 ",
                    Title : " Introductory Physics ",
                    view:false
                    },
                    {
                        id:5,   
                    Img: Physics ,
                        Code:"FSC 112 ",
                            Title : " Introductory Chemistry ",
                            view:false
                        },
                        {   id:6,
                            Img: Calculus ,
                            Code:"FSC 114 ",
                            Title : " Introductory Mathematics ",
                            view:false
                            },
                            {
                                id:7,
                                Img: Math ,
                                Code: "GEG 117 ",
                                Title : " Engneering Calculus ",
                                view:false
                                },
                                { id:8,
                                    Img: Physics ,
Code: "GEG 115 ",
                                    Title : " Engneering Algebra ",
                                    view:false
                                    },
                                    {
                                        id:9,
                                        Img: Calculus ,
                                        Code: "GEG 117 ",
                                        Title : " Applied Mathematics ",
                                        view:false
                                        },
                                        {
                                            id:10,
                                            Img: Math ,
                                            Code:"FSC 115 ",
                                            Title : " Introductory Physics ",
                                            view:false
                                            },
                                            {
                                                id:11,
Img: Physics ,
Code:"FSC 112 ",
                                                Title : " Introductory Chemistry ",
                                                view:false
                                                },
{
    id:12,
Img: Calculus ,
Code:"FSC 114 ",
Title : " Introductory Mathematics ",
view:false
},
]
export default CoursesDetails