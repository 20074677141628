import {createSlice} from '@reduxjs/toolkit';

const authSlice = createSlice({
	name: "auth",
	initialState: {
		auth: null,
	},
	reducers:{
		addaccess(state,action){
			const newItem = action.payload;
			state.auth = newItem;
		}
	}
})

export const authActions = authSlice.actions;
export default authSlice;