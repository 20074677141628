import { useState } from "react";
import {
  ForumChatArea,
  ForumReply,
  ForumSideNav,
  MobileForumHeader,
} from "../components/forum";
import { useLocation } from "react-router-dom";

const ForumChatRoom = () => {
  const [slideIn, setSlideIn] = useState(false);
  const location = useLocation();

  console.log({ location });

  return (
    <div className="w-full flex">
      <div className="flex md:w-[45%] lg:w-[20%]">
        <ForumSideNav slideIn={slideIn} onSlide={setSlideIn} />
        <MobileForumHeader slideIn={slideIn} onSlide={setSlideIn} />
      </div>
      <div className="flex lg:w-[80%]">
        <ForumChatArea />
        <ForumReply />
      </div>
    </div>
  );
};

export default ForumChatRoom;
