import aboutsvg from '../../assets/images/aboutsvg.svg'
const AboutBg = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center pt-2">
      <div className="flex flex-col-reverse md:flex-row-reverse h-[70%] justify-between items-center w-[90%]">
        <div className="w-full md:w-[50%] h-full py-4 px-2 flex flex-col justify-center text-white">
          <img src={aboutsvg} alt="about"/>
        </div>
        <div className="w-full md:w-[50%] h-full p-2 flex flex-col justify-center">
          <h2 className=" font-inter text-[2.25rem] lg:text-[2.7rem] font-[700] text-[#0B0B0B] pb-3">ABOUT US</h2>
          <p className="text-base md:text-lg lg:text-[1.5rem] font-[400] text-[#0B0B0B] font-Montserrat">
            TUTCOV is the home of passionate learners, united by a common goal -
            to unlock the full potential within. Here, curiosity meets
            knowledge, and questions find answers. TUTCOV is a Community-driven
            platform for empowering students in their educational journey.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

const AboutHero = () => {
  return (
    <div className=" ">
      
      <AboutBg />
    </div>
  );
};

export default AboutHero;
