import { Link } from "react-router-dom";
import MathImg from "../../assets/images/math.png";
import AccountingImg from "../../assets/images/accounting.png";
import ChemistryImg from "../../assets/images/chemistry.png";
import { HiArrowRight } from "react-icons/hi";

const CourseCard = ({ image, title }) => {
  return (
    <div className="h-[26.25rem] w-full  lg:w-[23.5rem] rounded-[1.25rem] border overflow-hidden">
      <div className="h-[17.25rem] w-full">
        <img src={image} alt="" className="w-full h-full" />
      </div>
      <div className="h-[9rem] w-full p-4 relative">
        <p className="lg:w-[70%] text-[1.5rem] font-[600]">{title}</p>
        <Link to={"/"} className="text-blue flex items-center gap-2 w-fit">
          <span className=" font-medium">See courses</span>
          <HiArrowRight />
        </Link>
        <div className="h-[9rem] w-[9rem] p-3 rounded-[50%] bg-gradient-1 border-none absolute right-0 bottom-0 translate-x-1/2 translate-y-1/2">
          <div className="h-full w-full p-3 rounded-[50%] bg-[rgba(57,169,219,.7)]  border-none">
            <div className="h-full w-full  rounded-[50%] bg-[rgba(96,15,246,.7)] border-none"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

function CourseSection() {
  return (
    <section className="w-full bg-white flex flex-col items-center justify-center  relative">
      <div className="w-[90%]">
        <div className="w-full monserate_font lg:w-[45%]">
          <h2 className="text-[1.5rem] lg:text-[3rem] font-[600] lg:font-[700]">
            Important Courses
          </h2>
          <p className="lg:text-[1.125rem]">
            Core courses for your department that may seem dauting.
          </p>
        </div>
        <div className="w-full flex flex-col gap-6 lg:flex-row mt-4 lg:justify-between lg:items-center">
          <CourseCard image={MathImg} title={"Engineering MathsGEG"} />
          <CourseCard
            image={AccountingImg}
            title={"Financial Accounting-ACC"}
          />
          <CourseCard
            image={ChemistryImg}
            title={"Chemistry and Biochem-CHM"}
          />
        </div>
      </div>
    </section>
  );
}

export default CourseSection;
