
import React, { useState } from 'react'
import { LuMail } from "react-icons/lu";
import { BiHide } from "react-icons/bi";
import { MdVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom"
import axios from 'axios'
const SignUp = () => {
  let navigate = useNavigate()
    
    const [Passwordvisib,setPasswordvisib] = useState(false)
    const [firstBox,setfirstBox] = useState(true) 
    const [details,setdetails] = useState({
      fullname : " ",
      email :  " ",
      password: " ",
      level :   " ",
      department : " ",
      faculty :  " ",
      rememberMe:false,
    })

    const updateDetails=(e)=>{
    const {name,value,checked,type} = e.target
    setdetails(prev=>{
      return {...prev,[name]: type === "checkbox" ? checked :value }
    })
    }
    //console.log(details)

    const url = 'https://tutcov-backend.onrender.com/accounts/signup/student/'
    const data = {
      email:details.email,
      username:details.fullname,
      password:details.password
    }

    const handleSubmit = () => {
      axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        //console.log(res);
        navigate("/login")
      }).catch((error) => {
        //console.error(error)
        //alert("unable to register, try again")
      })
      //
    }
    



  return (
    
    <div className='pt-[60px] '>
      
      
      <div className={`w-[80%] md:w-[25%]  bg-white border-[#D0D5DD] border-[1px] mb-6 rounded-[5px] py-3 mx-auto ` }>
        <div className='w-[60%] relative mx-auto'>
      <div className={`w-[100%] z-[10] border-[1px] ${firstBox? 'border-[#D0D5DD] bg-[#D0D5DD]': ' border-[#0066FF] bg-[#0066FF]' } absolute top-[50%]  z-[-20]`}></div>
        <div className='w-[100%] flex justify-between'>
        <button className={` ${firstBox ?'bg-[#0066FF] text-white ':' bg-white border-[#5E6798] border-[1px]'} w-6 h-6 rounded-full text-center z-[100] `} onClick={()=>{setfirstBox(true)}}>1</button>
        <button className={` ${firstBox ?'bg-white border-[#5E6798] border-[1px] ':' bg-[#0066FF] text-white '} w-6 h-6 rounded-full text-center z-[100] `} onClick={()=>{setfirstBox(false)}}>2</button>

        </div>
        </div>

      </div>



    <div >
      
  <div className={`border-[#D0D5DD] ${firstBox ? 'visible' :'hidden'}  bg-white py-3 border-[1px] w-[80%] space-y-3 mx-auto font-[Montserrat] rounded-[10px] md:w-[25%]`}>
    
    <div> 
     <p className='font-bold text-2xl text-center'>Create Your Account</p>
     <p className='text-[#667185] font-medium text-[11px] text-center'>Enter your credentials to access your account</p>
     </div>
     <div>
     <label>
       <span className='text-[#101928] text-[12px] pl-5'>FULL NAME </span>
       <div className='w-[90%] flex mx-auto'>
       <input type="text" placeholder='Enter Name' name="fullname" onChange={updateDetails} value={details.fullname}
       className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2'  />
       </div>
 
 
    </label>
 
     </div>

     <div>
     <label>
       <span className='text-[#101928] text-[12px] pl-5'>EMAIL ADDRESS </span>
       <div className='w-[90%] flex mx-auto relative'>
       <input type="text" placeholder='Enter Email' name="email" onChange={updateDetails} value={details.email}
       className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2'  />
       <span className='absolute right-2 top-2 text-[#D0D5DD] text-sm'><LuMail /></span>
       </div>
 
 
    </label>
 
     </div>
     <div >
     <label>
       <span className='text-[#101928] text-[12px] pl-5'>PASSWORD</span>
       <div className=' w-[90%] flex mx-auto relative'>
      <input type={Passwordvisib ? "text" :"Password"} name="password"onChange={updateDetails}  value={details.password}
       className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2' />
       <span className='absolute right-2 top-2 text-[#D0D5DD] text-sm'>{Passwordvisib ?<MdVisibility onClick={()=>{setPasswordvisib(!Passwordvisib)}} /> : <BiHide onClick={()=>{setPasswordvisib(!Passwordvisib)}} />}   </span>
       </div> 
     </label>
     </div>
 
     <div className='flex w-[90%] justify-between mx-auto'>
       <label>
       <input type="checkbox"  checked={details.rememberMe} name="rememberMe"  onChange={updateDetails}/>
       <span className='text-[12px] font-semibold '>Remember me for 30 days</span>
       </label>
       <button className='text-[12px] text-[#0066FF]  '>forgot Password?</button>
 
     </div>
     <div className="w-[90%] mx-auto">
 
       <button className='text-white bg-[#0066FF] w-[100%] py-2 rounded-[15px]' onClick={()=>{setfirstBox(false)}}>Proceed</button>
     </div>
     <div>
       <p className='text-center font-semibold text-[12px] text-[#98A2BE]'>Already have an account <span className="text-[#124DD1] cursor-pointer" onClick={()=>navigate('/login')}  >Log in</span></p>
     </div>
      </div> 

      

      
   <div className={`border-[#D0D5DD] border-[1px] w-[80%] ${!firstBox ? 'visible' :' hidden'} bg-white space-y-4 mx-auto font-[Montserrat] py-3 rounded-[10px] md:w-[25%] `}>
     <div> 
     <p className='font-bold text-2xl text-center'>Almost there!</p>
     <p className='text-[#667185] font-medium text-[10px] text-center'>
      Enter student details if applicable, we need these details to personalise your dashboard.</p>
     </div>
     <div>
     <label>
       <span className='text-[#101928] text-[12px] pl-5'>LEVEL</span>
       <div className='w-[90%] flex mx-auto'>
       <input type="text" placeholder='Enter your level' name="level"  onChange={updateDetails} value={details.level}
       className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2'  />
       </div>
 
 
    </label>
 
     </div>

     <div>
     <label>
       <span className='text-[#101928] text-[12px] pl-5'>DEPARTMENT</span>
       <div className='w-[90%]  mx-auto '>
       <input type="text" placeholder='Enter your department' name="department" onChange={updateDetails} value={details.department}
       className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2'  />
       
       </div>
 
 
    </label>
 
     </div>
     <div >
     <label>
       <span className='text-[#101928] text-[12px] pl-5'>FACULTY</span>
       <div className=' w-[90%]  mx-auto '>
      <input type="text" placeholder='Enter your faculty' name="faculty" onChange={updateDetails} value={details.faculty}
       className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2' />
       
       </div> 
     </label>
     </div>
 
  
     <div className="w-[90%] mx-auto">
 
       <button className='text-white bg-[#0066FF] w-[100%] py-2 rounded-[15px]' onClick={handleSubmit}>SIGN UP</button>
     </div>
 
      </div> 
      


     </div>
     </div>
     
  )
}

export default SignUp