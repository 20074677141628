import React, {useState} from 'react'
import profilepic from '../../assets/images/profilepic.png';
import Header from '../../components/dashboard/Header'
import MobileHeader from '../../components/dashboard/MobileHeader'

const Settings = () => {
	const [selectedImage, setSelectedImage] = useState(null);
	const [form, setForm] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		faculty: "",
		department: "",
		country: "",
		school: ""
	})
	//console.log(form)
	const handleImageChange = (e) => {
    const file = e.target.files[0];

	    if (file) {
	        // Read the selected image and set it in the state for preview
			const reader = new FileReader();
			reader.onloadend = () => {
			    setSelectedImage(reader.result);
			};
			reader.readAsDataURL(file);
	    } else {
	        setSelectedImage(null);
	    }
    };

	return (
		<div >
		    <MobileHeader/>
		    <Header title="Settings"/>
		    <div className="md:p-8">
				<div className="flex justify-between items-center p-4 md:p-0">
					<h2 className='font-satoshi font-medium text-base md:text-lg text-black'>Customize your settings</h2>
					<div className="flex gap-4">
						<button className="font-satoshi text-[#0066FF] text-base font-medium bg-[#FEFDFD] border-[#E6E6E6] border-[0.5px] rounded-[28px] w-24 h-8">Cancel</button>
						<button className="font-satoshi text-[#FEFDFD] text-base font-medium bg-[#0066FF] border-[#E6E6E6] border-[0.5px] rounded-[28px] w-24 h-8">Save </button>
					</div>
				</div>
				<div className="bg-[#FEFDFD] rounded-[24px] p-8 pt-6 mt-6 ">
					<div className="border-b-[1px] border-[#E6E6E6] pb-4">
						<h2 className="font-satoshi font-bold text-[#040404] text-2xl">General</h2>
						<p className="font-satoshi text-base text-[#28374B]">Customize your account</p>
					</div>
					<div>
						<h2 className="font-satoshi font-bold text-base text-[#040404] mb-3">Personal Details</h2>
						<div className="flex justify-start items-center">
							<img className="w-20 h-20 rounded-full" src={selectedImage? selectedImage : profilepic } alt="profile"/>
							<input className="pl-3 ml-4 font-satoshi font-medium text-sm" type="file" accept="image/*" onChange={handleImageChange} />
						</div>
						<form className="mt-8">
						    <div className="flex flex-col gap-6 md:grid grid-cols-2">
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="name" className="font-satoshi text-xs text-[#124DD1]">Full name</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="name" type="text" placeholder="e.g John Doe"
									    onChange={(e) => setForm({...form, name:e.target.value})}
									/>
								</div>
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="email" className="font-satoshi text-xs text-[#124DD1]">Email address</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="email" type="email" placeholder="e.g Kehindekehinde@gmail.com"
									    onChange={(e) => setForm({...form, email:e.target.value})}
									/>
								</div>
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="phoneNumber" className="font-satoshi text-xs text-[#124DD1]">Phone Number</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="phoneNumber" type="text" placeholder="e.g +234893456723"
									    onChange={(e) => setForm({...form, phoneNumber:e.target.value})}
									/>
								</div>
							</div>
							<h3 className="font-satoshi font-bold text-[#040404] text-base mt-8 mb-8">School Details</h3>
							<div className="flex flex-col gap-6 md:grid grid-cols-2">
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="faculty" className="font-satoshi text-xs text-[#124DD1]">Faculty</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="faculty" type="text" placeholder="e.g Faculty of Engineering"
									    onChange={(e) => setForm({...form, faculty:e.target.value})}
									/>
								</div>
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="country" className="font-satoshi text-xs text-[#124DD1]">Country</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="country" type="text" placeholder="e.g Nigeria"
									    onChange={(e) => setForm({...form, country:e.target.value})}
									/>
								</div>
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="department" className="font-satoshi text-xs text-[#124DD1]">Department</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="department" type="text" placeholder="e.g Electrical engineering"
									    onChange={(e) => setForm({...form, department:e.target.value})}
									/>
								</div>
								<div className="flex flex-col justify-center items-start gap-1">
									<label htmlFor="school" className="font-satoshi text-xs text-[#124DD1]">School</label>
									<input 
									    className="placeholder:text-xs text-base font-medium font-satoshi text-[#28374B] border-[1px] border-[#C9CDD2] rounded-[5px] w-80 md:w-[24rem] pl-4 h-10"
									    name="school" type="text" placeholder="e.g University Of Lagos"
									    onChange={(e) => setForm({...form, school:e.target.value})}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Settings