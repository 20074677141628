import { useEffect, useState } from "react";

const RatingBox = ({ total, value, className }) => {
  const [num, setNum] = useState(0);

  useEffect(() => {
    let interval = 3000;
    let duration = Math.floor(interval / total);
    const counter = setInterval(() => {
      setNum((prev) => prev + 1);
    }, duration);
    if (num === total) {
      clearInterval(counter);
    }
    return () => {
      clearInterval(counter);
    };
  }, [num, total]);
  return (
    <div
      className={`w-[50%] h-[50%] lg:w-[25%] lg:h-full border-blue bg-blue ${className} monserate_font  lg:rounded-br-[0rem] flex flex-col items-center justify-center`}
    >
      <p className="text-white font-[600] lg:text-[1.75rem]">{num}+</p>
      <p className="text-white text-[.75rem] lg:text-[1.125rem]">{value}</p>
    </div>
  );
};

const AllRating = () => {
  return (
    <div className="lg:h-[10rem] h-[15rem] w-full bg-blue  flex flex-wrap lg:p-8">
      <div className=" h-full w-full bg-army-blue p-0 m-0 border-blue  flex flex-wrap ">
        <RatingBox
          total={1000}
          value={"Resources"}
          className={
            "rounded-br-[2rem] lg:rounded-br-[0rem] lg:border-white lg:border-r-4"
          }
        />
        <RatingBox
          total={8000}
          value={"Students"}
          className={
            "rounded-bl-[2rem] lg:rounded-bl-[0rem]  lg:border-white lg:border-r-4"
          }
        />
        <RatingBox
          total={50}
          value={"Departments"}
          className={
            "rounded-tr-[2rem] lg:rounded-tr-[0rem]  lg:border-white lg:border-r-4"
          }
        />
        <RatingBox
          total={500}
          value={"Downloads"}
          className={"rounded-tl-[2rem] lg:rounded-tl-[0rem]"}
        />
      </div>
    </div>
  );
};

export default AllRating;
