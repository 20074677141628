import {configureStore} from '@reduxjs/toolkit';
import questionSlice from './questionSlice';
import sideBarSlice from './sideBarSlice';
import forumSlice from './forumSlice';
import authSlice from './authSlice';

const store = configureStore({
	reducer:{
		question: questionSlice.reducer,
		sideBar: sideBarSlice.reducer,
		forum: forumSlice.reducer,
		auth: authSlice.reducer
	}
});
export default store;