import React, {useState, useEffect} from 'react'
import Box from '../../assets/images/Box.svg'
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Modal from './Modal'

const courseList = [
    {course:"Engineering Maths 1", courseCode:"GEG 113"}, 
    {course:"Financial Accounting", courseCode:"ACC  113"}, 
    {course:"Chemistry", courseCode:"FSC 112"}, 
    {course:"Engineering Calculus 1", courseCode:"GEG 117"}, 
    {course:"Numerical method", courseCode:"GEG 419"}
]


const Card = ({course, style, setOpenModal, setActiveCourse}) => {
	return(
		<div className="rounded-[10px] w-[192px] bg-white shadow" style={style}>
			<img src={Box} alt="card"/>
			<div className="p-4 pb-6">
				<p className="text-black text-sm font-Montserrat font-semibold">{course.courseCode}</p>
				<button onClick={() => {setOpenModal(true); setActiveCourse(course)}} className="text-[#0066FF] font-Montserrat font-medium text-xs" >Attempt questions</button>
			</div>
		</div>
	)
}

const Course = ({courses}) => {
	const [current, setCurrent] = useState(0);
	const [openModal, setOpenModal] = useState(false)

	const handlePrev = (size) => {
		if (size >= 768) {
			size = size - 288
		} 
		const cardsPerPage = 5 - (Math.floor(size/192));
		if(current > 0){
			setCurrent(prev => prev-1)
		}else{
			setCurrent(cardsPerPage)
		}
	}
	const handleNext = (size) => {
		if (size >= 768) {
			size = size - 288
		} 
		const cardsPerPage = 5 - (Math.floor(size/192));
		if(current < cardsPerPage){
			setCurrent(prev => prev+1)
		}else{
			setCurrent(0)
		}
	}

	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [activeCourse, setActiveCourse] = useState(null);

	const handleResize = () => {
	    setWindowSize(window.innerWidth);
	};

	useEffect(() => {
        // Add event listener to listen for window resize events
	    window.addEventListener('resize', handleResize);

	    // Clean up the event listener on component unmount
	    return () => {
	        window.removeEventListener('resize', handleResize);
	    };
    }, []);



	return (
		<div className="relative mb-64 ">
		   <div className="flex justify-between items-center p-4 pl-10 pr-10 w-full">
			   <h3 className="text-black font-medium font-Montserrat text-lg">Newly added courses</h3>
			   <div className="flex items-center gap-3">
				   <span className="rounded-full shadow w-8 h-8 flex justify-center items-center"><FaArrowLeft onClick={() => handlePrev(windowSize)} className="text-[#0066FF] cursor-pointer" /></span>
				   <span className="rounded-full shadow w-8 h-8 flex justify-center items-center"><FaArrowRight onClick={() => handleNext(windowSize)} className="text-[#0066FF] cursor-pointer" /></span>
			   </div>
		   </div>
		   <div className="">
			   <div className="flex items-center gap-10 pl-10 pr-10 absolute w-fit">
				   {
				   	    courseList.map((item,index) => 
				   	    	<Card 
				   	    	    key={index}
				   	    	    course={item}
				   	    	    setOpenModal={setOpenModal}
				   	    	    setActiveCourse={setActiveCourse}
				   	    	    style={{
				                    transform: `translateX(-${current * 200}px)`,
				                }}
				   	    	/> 
				   	    )
				   }
			   </div>
		   </div>
		   <div className="">
		   	{openModal && <Modal course={activeCourse} setOpenModal={setOpenModal}/>}
		   </div>
		</div>
	)
}

export default Course