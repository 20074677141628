const AboutStory = () => {
  return (
    <section className="w-full p-4 lg:py-[2rem] flex flex-col items-center">
      <div className="md:w-[90%] flex">
        <div className="w-full lg:w-[50%]">
          <h2 className="text-[2.25rem] font-[700]">Our Story</h2>
          <p className="text-base lg:text-[1.2rem]">
            As students of Unilag, Tobi, Fiyin and Wande made an important
            observation: though past questions were helpful, they were scarce
            commodities. This scarcity drove the price of past questions through
            the roof, leaving those who couldn’t afford them riddled with
            academic challenges. This realization resulted in the birth of a
            solution - TUTCOV, whose goal is to bridge the distance between
            students and accessibility to quality learning materials.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutStory;
