import React, { useRef, useState } from 'react'


import { useNavigate } from "react-router-dom"
const EmailVef = () => {

  const [inputs, setInputs] = useState(Array(5).fill(''));
  const inputRefs = useRef([]);

  



  const handleChange =(e,index)=>{
   const {value} = e.target
   const  newInputs = [...inputs]
   newInputs[index] =value;
   setInputs(newInputs)

   if (value && index < inputRefs.current.length - 1) {
    inputRefs.current[index + 1].focus();
    inputRefs.current[index].blur();
    inputRefs.current[index + 1].style.borderColor =" #407BFF"
    inputRefs.current[index].style.borderColor ="#D0D5DD "

  }

  }

  const handleKeyPress = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !inputs[index]) {
      // Move focus to the previous input field on backspace if the current input is empty
      inputRefs.current[index - 1].focus();
      inputRefs.current[index - 1].style.borderColor ="  #407BFF"
      inputRefs.current[index].style.borderColor =" #D0D5DD  "
    }
  };
  console.log(inputs)
  

    let navigate = useNavigate()
  return (
    <div className='py-[120px]'>
    <div className='border-[#D0D5DD]  bg-white border-[1px] py-5 w-[75%] mx-auto font-[Montserrat] rounded-[10px] md:w-[25%]'>
    <form className='space-y-3'>
     <div> 
     <p className='font-bold text-2xl text-center'>Verify Your Email </p>
     <p className='text-[#667185] font-medium text-[11px] text-center'>Enter the code that was sent to your email</p>
     </div>
     <div className='border-[#D0D5DD] border-[1px] w-[90%] py-7 mx-auto rounded-md'>
    <div className='w-[70%] mx-auto flex justify-between '>
      {inputs.map((input,index)=>{
        return <input type="number" maxLength={1} 
        onKeyDown={(e) => handleKeyPress(e, index)}
        onChange={(e)=>{handleChange(e,index)}}
        ref={(ref) => {
          inputRefs.current[index] = ref;
        }}
       
        className='w-[30px] h-[30px] transition text-center border-[#5E6978] border-[1px] rounded-md bg-[#E4E4E4]  outline-none'
        
        />
        
      })}
     </div>
     </div>

     <div className="w-[90%] mx-auto">
 
       <button className='text-white bg-[#0066FF] w-[100%] py-2 rounded-[18px]'>Verify</button>
     </div>
     <div>
       <p className='text-center font-semibold text-[12px] text-[#98A2BE]'>Already have an account <span className="text-[#124DD1] cursor-pointer" onClick={()=>navigate("/login")}>Log in</span></p>
     </div>
 
 
    </form>
       
 
 
     </div>
     </div>
  )
}

export default EmailVef