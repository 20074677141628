import { useLocation } from "react-router-dom";
import { FacultyForumList, LevelForumList } from "../components/forum";

const Forum = () => {
  const location = useLocation();
  const faculty = location.state?.faculty ? location.state?.faculty : "";
  return <main>{!faculty ? <FacultyForumList /> : <LevelForumList />}</main>;
};

export default Forum;
