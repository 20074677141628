import { LiaTimesSolid } from "react-icons/lia";
import TypeMessage from "./TypeMessage";
import ReplyPost from "./ReplyPost";
import { Link, useLocation } from "react-router-dom";
const ForumReply = () => {
  const location = useLocation();
  const postId = location.state?.postId ? location.state?.postId : "";

  return (
    <div
      className={`w-full mt-[7vh] lg:mt-0 ${
        postId
          ? "w-full flex lg:w-[30%] lg:static absolute top-0 right-0"
          : "lg:w-0 hidden"
      } h-screen bg-dark-blue   flex-col `}
    >
      <nav className="h-[5vh] w-full flex flex-col p-4 sticky top-0 left-0">
        <Link to={`/forum/room`} state={{ postId: "" }} className="self-end">
          <LiaTimesSolid size={30} className="text-white" />
        </Link>
      </nav>
      <div className="h-[80vh] lg:h-[90vh]  mt-[5vh]  flex-col justify-between">
        <div className="overflow-y-scroll h-[90%] p-4 flex flex-col gap-4">
          <ReplyPost />
          <ReplyPost />
          <ReplyPost />
          <ReplyPost />
        </div>
        <div className=" px-4 py-2">
          <TypeMessage />
        </div>
      </div>
    </div>
  );
};

export default ForumReply;
