import React from 'react'
import bellicon from '../../assets/icons/bellicon.svg'
import image from '../../assets/images/Image.png';


const Header = ({title}) => {
	

	return (
		<div className="pl-3 pr-3 md:pl-10 md:pr-10 w-full h-16 flex justify-between items-center shadow-sm bg-white">
			<h2 className=" text-[#0B0B0B] font-semibold font-Montserrat text-lg md:text-2xl">{title}</h2>
			<div className="flex justify-end items-center gap-4">
				<input className="bg-[#EFF8FF] placeholder:text-[#667185] text-sm font-inter h-10 w-fit md:w-[22rem] pl-4 rounded-[8px]" type="text" placeholder="Search for anything"/>
				<div className="bg-[#F0F2F5] rounded-full w-10 h-10 flex justify-center items-center md:ml-2"><img className="" src={bellicon} alt="bell"/></div>
				<img className="w-10 h-10 rounded-full" src={image} alt="pic"/>
			</div>
		</div>
	)
}

export default Header