import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import image from '../../assets/images/Image.png';
import signout from '../../assets/icons/sign-out.png';
import { FaChevronUp } from "react-icons/fa6";
import { FaMinus, FaCheck } from "react-icons/fa";
import {useSelector} from 'react-redux';

const Review = () => {
	const navigate = useNavigate();
	const [answerToggle, setAnswerToggle] = useState(false);
	const question = useSelector(state => state.question.question);
	const grade = useSelector(state => state.question.grade);
	const [toggleId, setToggleId] = useState();
	const [page, setPage] = useState(1);
	let questionsPerPage = 5;
	let numberOfPages;
	if (question.length % questionsPerPage > 0){
		numberOfPages = Math.floor(question.length/questionsPerPage) + 1;
	}else{
		numberOfPages = Math.floor(question.length/questionsPerPage) ;
	}
	let startNumber = (page-1) * questionsPerPage; // the number the question is starting from
	let endNumber = page * questionsPerPage; //the last question on the page
	const displayQuestion = question.slice(startNumber, endNumber); //the particular questions to be displayed
	const [level, setLevel] = useState(null) //to be deleted later, redundant

	const handlePrev = () => {
		if(page > 1){
			setPage(prev => prev - 1);
		}
	}

	const handleNext = () => {
		if(page < numberOfPages){
			setPage(prev => prev + 1)
		}
	}

	const handleSubmit = () => {
		console.log(question)
		navigate('/dashboard')
	}
	let graded = grade/12 * 100
	const [style, setStyle ] = useState(null)

	useEffect(() => {
		if (graded >= 80) {
            setLevel(1);
		} else if (graded >= 60 && graded < 80) {
		    setLevel(2);
		} else if (graded >= 45 && graded < 60) {
		    setLevel(3);
		} else if (graded >= 20 && graded < 45) {
		    setLevel(4);
		} else {
		    setLevel(4);
		}
		const styles = () => {
			if(level === 1){
				return('border-[#124DD1] border-l-white')
			}else if (level ===2){
				return('border-[#124DD1] border-l-white border-b-white')
			}else if(level===3){
				return('border-t-[#124DD1] border-white')
			}else{
				return('border-white')
			}
		}
		setStyle(styles);

	}, [graded, level])


	return (
		<div className="flex flex-col w-screen">
		    <div className="bg-[#FFFFFF] p-4 border-b-[3px] border-[#0066FF] flex justify-center items-center">
		    	<p className='text-[#1D2939] text-xl font-bold font-inter'>FSC 115 - 2015 - Review</p>
		    </div>
			<div className="p-8 md:p-10 bg-pattern flex flex-col md:grid grid-cols-[calc(100%-25rem)_20rem] w-screen gap-12">
			    <div className="w-full">
				    { displayQuestion.map((item,i) => 
						<div className="py-8"
						    key={item.id}>
							<div className="border-[1px] border-[#D0D5DD] rounded-[16px] px-4 w-full bg-[#FBFBFB]">
								<h2 className="text-[#344054] text-sm font-medium font-Montserrat border-b-[1px] py-2">Question {item.questionNumber}</h2>
								<p className="text-[#344054] text-lg md:text-xl font-regular font-Montserrat py-4">{item.question}</p>
							</div>
							<ul className="mt-6 pl-4">
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button className={`w-[1.2rem] h-[1.2rem] border-[1.85px] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center ${item.answer === 'A'? "border-[#039855]" : item.pickedAnswer === 'A' ? "border-[#D92D20]" : "border-[#D0D5DD]"}`}>
									    {item.answer === 'A'? <FaCheck className="text-sm text-[#039855]"/> : item.pickedAnswer === 'A' ? <FaMinus className="text-sm text-[#D92D20]" /> :""}
									</button><p> A.</p><p className="pl-2 ">{item.options[0]}</p>
								</li>
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button className={`w-[1.2rem] h-[1.2rem] border-[1.85px] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center ${item.answer === 'B'? "border-[#039855]" : item.pickedAnswer === 'B' ? "border-[#D92D20]" : "border-[#D0D5DD]"}`}>
									    {item.answer === 'B'? <FaCheck className="text-sm text-[#039855]"/> : item.pickedAnswer === 'B' ? <FaMinus className="text-sm text-[#D92D20]" /> :""}
									</button><p> B.</p><p className="pl-2 ">{item.options[1]}</p>
								</li>
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button className={`w-[1.2rem] h-[1.2rem] border-[1.85px] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center ${item.answer === 'C'? "border-[#039855]" : item.pickedAnswer === 'C' ? "border-[#D92D20]" : "border-[#D0D5DD]"}`}>
									    {item.answer === 'C'? <FaCheck className="text-sm text-[#039855]"/> : item.pickedAnswer === 'C' ? <FaMinus className="text-sm text-[#D92D20]" /> :""}
									</button><p> C.</p><p className="pl-2 ">{item.options[2]}</p>
								</li>
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button className={`w-[1.2rem] h-[1.2rem] border-[1.85px] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center ${item.answer === 'D'? "border-[#039855]" : item.pickedAnswer === 'D' ? "border-[#D92D20]" : "border-[#D0D5DD]"}`}>
									    {item.answer === 'D'? <FaCheck className="text-sm text-[#039855]"/> : item.pickedAnswer === 'D' ? <FaMinus className="text-sm text-[#D92D20]" /> :""}
									</button><p> D.</p><p className="pl-2 ">{item.options[3]}</p>
								</li>
							</ul>
							<div className="border-[1px] border-[#D0D5DD] rounded-[16px] borderIncrease px-4 w-full bg-[#FBFBFB] px-4 mt-8">
								<div className="text-[#344054] font-medium font-Montserrat text-sm p-3 pl-0 flex justify-between items-center border-b-[1px] mb-2">
								    <p className="">Answer</p>
								    <FaChevronUp className={`cursor-pointer ${answerToggle && toggleId === item.id ? 'down': 'up'}`}  onClick={() => {setAnswerToggle(prev => !prev); setToggleId(item.id)}}/>
								</div>
								{answerToggle && toggleId === item.id && <div>
									<p className="text-[#344054] text-lg md:text-xl font-regular font-Montserrat py-4 ">{item.question}</p>
								</div>}
							</div>
						</div>
					)}
					<div className="flex justify-between items-center pt-8">
						<button disabled={page === 1} onClick={handlePrev} className="font-inter text-sm font-medium text-[#FBFBFB] bg-[#124DD1] w-[5.75rem] h-[2.375rem] rounded-[6px]">Previous</button>
						<p className="font-inter text-sm text-[#101828]">Page {page} of {numberOfPages}</p>
						<button onClick={page === numberOfPages ? handleSubmit : handleNext} className={`font-inter text-sm font-medium text-[#FBFBFB] bg-[#124DD1] h-[2.375rem] rounded-[6px] ${page === numberOfPages ? "w-[6.75rem]" :"w-[4.125rem]"}`}>{page === numberOfPages ? "Back to home" : "Next"}</button>
					</div>
				</div>
				<div className="bg-[#08132A] w-[20rem] mr-auto ml-auto md:mr-0 md:ml-4 p-3 h-fit">
					<p className="font-bold font-inter text-[#FBFBFB] text-lg md:text-xl py-7 pl-10  border-b-[1px] border-[#FBFBFB]">Your Board</p>
					<div className="grid place-items-center grid-cols-5 py-6">
						{
							question.map((item,i) => 
								<div className={`w-[2.75rem] h-[2.75rem] rounded-[8px] font-inter font-medium text-[#E0F2FE] text-base flex justify-center items-center m-2 ${item.pickedAnswer === item.answer ? 'bg-[#12B76A]' : 'bg-[#F04438]'}`}>#{item.questionNumber}</div>
							)
						}
					</div>
					<div className="bg-black flex flex-col justify-center gap-8 p-6">
						<div className={`self-center border-[10px] rounded-[50%] w-[148px] h-[148px] flex flex-col justify-center items-center rotate-45 ${style}`}>
						    <div className="flex flex-col justify-center items-center -rotate-45">
							    <p className="text-[#FBFBFB] text-sm">Score</p>
							    <p className="text-[#FBFBFB] text-3xl font-bold">{grade}<span className="text-[#FBFBFB] text-base font-medium">/12</span></p>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<p className="font-inter font-normal text-sm md:text-base text-[#F2F4F7]">Number of attempt: <span className="font-bold text-[#FBFBFB]">1</span></p>
							<p className="font-inter font-normal text-sm md:text-base text-[#F2F4F7]">Aggregated score: <span className="font-bold text-[#FBFBFB]">{grade}</span></p>
						</div>
					</div>
					<div className='flex justify-between items-center gap-3 p-3 mt-12'>
						<div className="flex items-center gap-4">
							<img className="w-10 h-10 rounded-full" src={image} alt="pic"/>
							<div className="">
								<p className="text-white font-semibold font-inter text-sm">John Doe</p>
								<p className="text-[#E3EFFC] text-xs font-inter">johndoe@gmail.com</p>
							</div>
						</div>
						<img src={signout} alt="logout"/>
				    </div>
				</div>
			</div>
		</div>
	)
}

export default Review;