import React from 'react'
import { useState } from 'react'

const Form = () => {

  const [form ,setform] = useState({
    firstname : " ",
    lastname :"",
    Email : "",
    phoneNumber: "",
    mytext: " "


  })

  const updateForm =(e)=>{
    const {name,value} = e.target
    setform(prev=> {return {...prev,[name]:value}})
  }


  return (
    <div className=' md:border-[#DOD5DD] md:border-b-[1px]'>
    <div className='bg-[#FBFBFB] w-[90%] font-[Montserrat] py-8  m-auto rounded-[7px] translate-y-[-100px] shadow-[7px_8px_4px_0px_#00000040] md:w-[50%]'>
      <div className='w-[75%] m-auto  md:w-[70%]'>
        <div className='mb-8' >
            <h1 className='text-4xl font-bold mb-3'>Get In Touch</h1>
            <p className='font-medium text-[1rem]'>we would love to hear from you</p>

        </div>
        <form>
          <div className='space-y-3 mb-6  '>
            <div className='md:flex  md:w-full m-auto justify-between'>
        <label className='flex flex-col items-start w-full space-y-1  md:w-[45%] ' >
          <span className='text-sm font-medium '> First Name</span>
          
          <input type="text"  name="firstname"  placeholder=" First Name" value={form.firstname} onChange={updateForm}
          className='border-[1px] border-[#939BA5] w-full rounded-[7px]  bg-[#fBFBFB] p-2 text-[0.8rem]' />
        </label>
       

        <label className='flex flex-col w-full space-y-1 md:w-[45%]'>
          <span  className='text-sm font-medium '>Last Name</span>
          <input type="text"  value={form.lastname} placeholder=" Last Name" name="lastname" onChange={updateForm}
          className='border-[1px] border-[#939BA5] w-full rounded-[7px] bg-[#fBFBFB] p-2 text-[0.8rem]' />
        </label>
        </div>
        

        <label className='flex flex-col w-full space-y-1  ' >
          <span className='text-sm font-medium ' >Email</span>
          <input type="text"  name="Email" value={form.Email} placeholder=" Email" onChange={updateForm}
           className='border-[1px] border-[#939BA5] w-full rounded-[7px]  bg-[#fBFBFB] p-2 text-[0.8rem]' />
          </label>
        
        
        <label className='flex flex-col w-full space-y-1  ' >
          <span  className='text-sm font-medium '>Phone Number</span>
          <input type="number"  name="phoneNumber" value={form.phoneNumber} placeholder={+234812345678}  onChange={updateForm}
          className='border-[1px] border-[#939BA5] w-full rounded-[7px]  bg-[#fBFBFB] p-2 text-[0.8rem]' />
          </label>
        

        <label className='flex flex-col w-full '>
          <span>Message</span>

        <textarea  className='border-[1px] border-[#939BA5] w-full rounded-[7px]  bg-[#fBFBFB] py-5 outline-none' 
        style={{resize:"none"}} name="mytext" value={form.mytext} onChange={updateForm} />

        </label>
        <button className='bg-[#0066FF] text-[#fff] w-full rounded-[7px] py-2  mx-auto font-medium text-xl text-center'>Send Message</button>


        </div>
        
        


  


        </form>
        </div>
      
    </div>
    </div>
  )
}

export default Form
