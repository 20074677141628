import React, {useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import image from '../../assets/images/Image.png';
import signout from '../../assets/icons/sign-out.png';
import {useDispatch, useSelector} from 'react-redux';
import {questionActions} from '../../store/questionSlice';
import { FaCheck } from "react-icons/fa";
import QuestionModal from '../../components/dashboard/QuestionModal'

const Question = () => {
	//const {question_id} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	let questionsPerPage = 5;
	let questionList = [
	    {
	    	id: 123,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 1
	    },
	    {
	    	id: 134,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'B',
	    	questionNumber: 2
	    },
	    {
	    	id: 145,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 3
	    },
	    {
	    	id: 156,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 4
	    },
	    {
	    	id: 167,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 5
	    },
	    {
	    	id: 177,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'C',
	    	questionNumber: 6
	    },
	    {
	    	id: 187,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 7
	    },
	    {
	    	id: 197,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 8
	    },
	    {
	    	id: 198,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 9
	    },
	    {
	    	id: 194,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 10
	    },
	    {
	    	id: 169,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 11
	    },
	    {
	    	id: 1979,
	    	question: "How many questions do we want to be here?",
	    	options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
	    	pickedAnswer: '',
	    	answer:'A',
	    	questionNumber: 12
	    },
	]

	const [question, setQuestion] = useState(questionList); // The question lists to be displayed
	const openModal = useSelector(state => state.question.openModal)
	let numberOfPages;
	if (question.length % questionsPerPage > 0){
		numberOfPages = Math.floor(question.length/questionsPerPage) + 1;
	}else{
		numberOfPages = Math.floor(question.length/questionsPerPage) ;
	}
	let startNumber = (page-1) * questionsPerPage; // the number the question is starting from
	let endNumber = page * questionsPerPage; //the last question on the page
	const displayQuestion = question.slice(startNumber, endNumber); //the particular questions to be displayed


	const handleButtonA = (id) => {
		setQuestion(
			question.map((item) =>{
				if(item.id === id){
					return {...item, pickedAnswer:'A'}
				}
				return item
			})
		)
	}
	const handleButtonB = (id) => {
		setQuestion(
			question.map((item) =>{
				if(item.id === id){
					return {...item, pickedAnswer:'B'}
				}
				return item
			})
		)
	}
	const handleButtonC = (id) => {
		setQuestion(
			question.map((item) =>{
				if(item.id === id){
					return {...item, pickedAnswer:'C'}
				}
				return item
			})
		)
	}
	const handleButtonD = (id) => {
		setQuestion(
			question.map((item) =>{
				if(item.id === id){
					return {...item, pickedAnswer:'D'}
				}
				return item
			})
		)
	}

	const handlePrev = () => {
		if(page > 1){
			setPage(prev => prev - 1);
		}
	}

	const handleNext = () => {
		if(page < numberOfPages){
			setPage(prev => prev + 1)
		}
	}

	const handleSubmit = () => {
		dispatch(questionActions.setOpenModalTrue());
	}

	

	return (
		<div className="flex flex-col w-screen">
		    <div className="bg-[#FFFFFF] p-4 border-b-[3px] border-[#0066FF] flex justify-center items-center">
		    	<p className='text-[#1D2939] text-xl font-bold font-inter'>FSC 115 - 2015</p>
		    </div>
			<div className="p-8 md:p-10 bg-pattern flex flex-col md:grid grid-cols-[calc(100%-25rem)_20rem] w-screen gap-12">
			    <div className="w-full">
				    { displayQuestion.map((item,i) => 
						<div className="py-6"
						    key={item.id}>
							<div className="border-[1px] border-[#D0D5DD] rounded-[16px] px-4 w-full bg-[#FBFBFB]">
								<h2 className="text-[#344054] text-sm font-medium font-Montserrat border-b-[1px] py-2">Question {item.questionNumber}</h2>
								<p className="text-[#344054] text-lg md:text-xl font-regular font-Montserrat py-4">{item.question}</p>
							</div>
							<ul className="mt-6 pl-4">
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button onClick={() => handleButtonA(item.id)} className={`w-[1.2rem] h-[1.2rem] border-[1.85px] border-[#124DD1] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center`}><FaCheck className={`text-sm ${item.pickedAnswer === 'A'  ? "text-[#124DD1]" : "hidden"}`} /></button><p> A.</p><p className="pl-2 ">{item.options[0]}</p>
								</li>
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button onClick={() => handleButtonB(item.id)} className={`w-[1.2rem] h-[1.2rem] border-[1.85px] border-[#124DD1] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center`}><FaCheck className={`text-sm ${item.pickedAnswer === 'B'  ? "text-[#124DD1]" : "hidden"}`} /></button><p> B.</p><p className="pl-2 ">{item.options[1]}</p>
								</li>
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button onClick={() => handleButtonC(item.id)} className={`w-[1.2rem] h-[1.2rem] border-[1.85px] border-[#124DD1] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center`}><FaCheck className={`text-sm ${item.pickedAnswer === 'C'  ? "text-[#124DD1]" : "hidden"}`} /></button><p> C.</p><p className="pl-2 ">{item.options[2]}</p>
								</li>
								<li className="flex items-center gap-1 text-[#344054] font-inter text-base md:text-lg pt-2">
									<button onClick={() => handleButtonD(item.id)} className={`w-[1.2rem] h-[1.2rem] border-[1.85px] border-[#124DD1] rounded-[5px] bg-[#F0F9FF] flex justify-center items-center`}><FaCheck className={`text-sm ${item.pickedAnswer === 'D'  ? "text-[#124DD1]" : "hidden"}`} /></button><p> D.</p><p className="pl-2 ">{item.options[3]}</p>
								</li>
							</ul>
						</div>
					)}
					<div className="flex justify-between items-center pt-8">
						<button disabled={page === 1} onClick={handlePrev} className="font-inter text-sm font-medium text-[#FBFBFB] bg-[#124DD1] w-[5.75rem] h-[2.375rem] rounded-[6px]">Previous</button>
						<p className="font-inter text-sm text-[#101828]">Page {page} of {numberOfPages}</p>
						<button onClick={page === numberOfPages ? handleSubmit : handleNext} className={`font-inter text-sm font-medium text-[#FBFBFB] bg-[#124DD1] h-[2.375rem] rounded-[6px] ${page === numberOfPages ? "w-[4.75rem]" :"w-[4.125rem]"}`}>{page === numberOfPages ? "Submit" : "Next"}</button>
					</div>
				</div>
				<div className="bg-[#08132A] w-[20rem] mr-auto ml-auto md:mr-0 md:ml-4 p-3 h-fit">
					<p className="font-bold font-inter text-[#FBFBFB] text-lg md:text-xl py-7 pl-10  border-b-[1px] border-[#FBFBFB]">Your Board</p>
					<div className="grid place-items-center grid-cols-5 py-6">
						{
							question.map((item,i) => 
								<div key={i} className={`w-[2.75rem] h-[2.75rem] rounded-[8px] font-inter font-medium text-base flex justify-center items-center m-2 text-[#E0F2FE] ${item.pickedAnswer === ""? 'bg-[#667085] ': 'bg-[#0066FF]'}`}>#{item.questionNumber}</div>
							)
						}
					</div>
					<div className='flex justify-between items-center gap-3 p-3 mt-12'>
						<div className="flex items-center gap-4">
							<img className="w-10 h-10 rounded-full" src={image} alt="pic"/>
							<div className="">
								<p className="text-white font-semibold font-inter text-sm">John Doe</p>
								<p className="text-[#E3EFFC] text-xs font-inter">johndoe@gmail.com</p>
							</div>
						</div>
						<img src={signout} alt="logout"/>
				    </div>
				</div>
			</div>
			<div className="bg-gradient"></div>
			{openModal && <div> <QuestionModal question={question}/> </div>}
		</div>
	)
}

export default Question