import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {questionActions} from '../../store/questionSlice';
import star_struck from '../../assets/images/star_struck.png'
import smiling_face_with_halo from '../../assets/images/smiling_face_with_halo.png'
import winking_face from '../../assets/images/winking_face.png'
import smiling_face_with_smiling_eyes from '../../assets/images/smiling_face_with_smiling_eyes.png'

const GradePage = () => {
	const question = useSelector(state => state.question.question);
	const dispatch = useDispatch();
	let numberOfQuestion = question.length;
	let correctAnswers = question.filter(item => item.pickedAnswer === item.answer);
	let numberOfCorrectAnswer = correctAnswers.length
	let grade = numberOfCorrectAnswer/numberOfQuestion * 100
	const [level, setLevel] = useState(null)

	useEffect(() => {
		if (grade >= 80) {
            setLevel(1);
		} else if (grade >= 60 && grade < 80) {
		    setLevel(2);
		} else if (grade >= 45 && grade < 60) {
		    setLevel(3);
		} else if (grade >= 20 && grade < 45) {
		    setLevel(4);
		} else {
		    setLevel(4);
		}
		dispatch(questionActions.setGrade(numberOfCorrectAnswer))
	}, [grade])

	const GradePageComponent = ({src, message, correct, total}) => {
		return(
			<div className="bg-pattern w-full h-screen flex justify-center items-start md:items-center px-8">
		    	<div className="flex flex-col items-center justify-center gap-6 w-full md:w-[584px] bg-white rounded-[32px] shadow p-8 mt-36 md:mt-0">
		    		<img src={src} className="w-[100px] h-[100px]" alt={`${src}`}/>
		    		<h3 className="font-Montserrat font-semibold text-lg md:text-2xl text-[#101928] text-center">{message}</h3>
		    		<p className="font-inter font-medium text-[#667185] text-base">Grace, your score is <span className="font-inter font-semibold text-[#124DD1]">{correct}/{total}</span></p>
		    		<div className="flex flex-col md:flex-row justify-between items-center gap-4 ">
					    <Link to="/dashboard/022/011" className="font-Montserrat font-semibold text-base text-[#0B0B0B] border-[2px] border-[#124DD1] bg-[#EEF4FF] w-[180px] h-[51px] rounded-[50px] flex justify-center items-center">Review Test</Link>
					    <Link to="/dashboard" className="font-Montserrat font-semibold text-base text-white bg-[#0066FF] w-[180px] h-[51px] rounded-[50px] flex justify-center items-center">Go home</Link>
				    </div>
		    	</div>
		    </div>
		)
	}


	return (
		<div>
			<div className="bg-[#FFFFFF] p-4 border-b-[3px] border-[#0066FF] flex justify-center items-center">
		    	<p className='text-[#1D2939] text-xl font-bold font-inter'>FSC 115 - 2015 - Review</p>
		    </div>
		    {level === 1 && <GradePageComponent src={star_struck} message="Congratulations! You Aced the Test!" correct={numberOfCorrectAnswer} total={numberOfQuestion}/>}
		    {level === 2 &&<GradePageComponent src={smiling_face_with_halo} message="Impressive Performance!" correct={numberOfCorrectAnswer} total={numberOfQuestion}/>}
		    {level === 3 &&<GradePageComponent src={winking_face} message="Almost there" correct={numberOfCorrectAnswer} total={numberOfQuestion}/>}
		    {level === 4 &&<GradePageComponent src={smiling_face_with_smiling_eyes} message="Keep Going!" correct={numberOfCorrectAnswer} total={numberOfQuestion}/>}
		</div>
	)
}

export default GradePage