import Tasks from "../../assets/images/Taskbar.png";


function Task(){
    return(
        <section className=" hidden md:block">
            <div className=" w-full  bg-[#0066FF] pt-[5rem]  ">
                
                <div className=" flex justify-between pt-[5rem]" >
                <div className=" text-white pl-[4rem]  space-y-5 mt-[5rem]">
            <h3 className="text-5xl  font-bold space-y-3 flex flex-col mb-8 font-montseratt">
                <span className="block">Join 2000+ students</span>
                <span>Learning with Tutcov</span>
                


    
            
            </h3>
            
            
            <ul className="flex flex-col space-y-5 text-[18px] font-medium marker:white-500 list-disc pl-5 font-montseratt"> 
                        <li>Get help from certified tutors</li>
                        <li>Ask questions and learn new concepts</li>
                        <li>Get answers to all questions with detailed explanation</li>
                        
                        </ul>

                    
        
                </div>

                <div className="w-[50%] ">
                    <img src={Tasks} alt="" className="w-[100%]"/>
                </div>
               

            </div>
            </div>

        </section>
    )
}
export  default  Task