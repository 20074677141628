import { RoundedBtn } from "../ui";
import { AllRating } from "../common";
import {useNavigate} from 'react-router-dom';

const GetAccess = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full px-4 py-8 border-2 bg-[#F8F9FA] flex flex-col items-center justify-center ">
      <div className="w-full  lg:w-[90%] ">
        <div className="monserate_font text-[2.25rem] font-semibold lg:text-[4.5rem] w-full lg:w-[80%] relative">
          <span className="absolute h-3 bg-button-color w-[8rem] top-0 left-[20%] lg:top-[10%]"></span>
          <span className="absolute h-3 bg-button-color w-[8rem] bottom-[32%] left-[50%] lg:bottom-[7%] lg:left-[35%]"></span>
          <h1 className="">
            <span>Getting Access To </span>
            <span>Quality Materials </span>
            <span>Is Now Easy</span>
          </h1>
        </div>
        <p className="monserate_font font-medium lg:text-[18px] lg:w-[60%]">
          Transform the way you learn by getting access to educational resources
          tailored to meet your learning and give you the best chance to enhance
          your academic success.
        </p>
        <div className="mt-4 flex flex-col lg:flex-row gap-4 lg:w-[50%]">
          <RoundedBtn
            onClick={() => navigate('/dashboard')}
            name="Start Learning"
            className="w-full bg-button-color text-white font-[600]"
          />
          <RoundedBtn
            onClick={() => navigate('/about')}
            name="Learn more"
            className="w-full bg-white text-black border border-black font-[600]"
          />
        </div>
      </div>
    </div>
  );
};

const BackGroundHero = () => {
  return (
    <div>
      <div className="bg-hero_img h-[20rem] lg:h-[35rem] w-full bg-cover bg-center "></div>
      <AllRating />
    </div>
  );
};

function HomeHero() {
  return (
    <section>
      <GetAccess />
      <BackGroundHero />
    </section>
  );
}

export default HomeHero;
