import React from 'react'
import axios from 'axios'

const TestingPage = () => {
	//const urll = 'https://tutcov-backend.onrender.com/accounts/login/'
	const url = 'https://tutcov-backend.onrender.com/accounts/signup/student/'
    const data = {
	    email:"mic888277834@mail.com",
	    username: "micha99923333455",
	    password:"654321!@12"
    }

	const handleSubmit = () => {
		axios.post(url, data, {
			headers:{
				//Accept: "application/json",
                "Content-Type": "application/json"
			}
			//"withCredentials": true,
		})
        .then((res) => {
            console.log(res);
        });
	}
	return (
		<div>
			<button className="py-24" onClick={handleSubmit}>Submit</button>
		</div>
	)
}

export default TestingPage