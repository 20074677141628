const AcademicCard = ({ className, heading, content }) => {
  return (
    <div
      className={`w-full ${className} p-4 text-center flex flex-col gap-4 h-full items-center`}
    >
      <h3 className="text-[1.5rem] font-[600] w-full lg:w-[90%]">{heading}</h3>
      <p>{content}</p>
    </div>
  );
};

const AboutAcademic = () => {
  return (
    <section className="w-full p-4 lg:py-[2rem] flex flex-col items-center">
      <div className="md:w-[90%] flex flex-col lg:flex-row items-center lg:items-start lg:justify-between gap-8 lg:gap-0">
        <div className="w-full lg:w-[45%] flex flex-col gap-4">
          <h3 className="text-[1.8rem] lg:text-[2.25rem] font-[700]">
            With <span className="text-blue">TUTCOV</span>, academic success is
            in the palm of your hands.
          </h3>
          <h4 className="text-[1.2rem] lg:text-[1.5rem] font-[600]">
            Get access to 100+ Past questions at all levels
          </h4>
          <p className="text-base">
            TUTCOV was crated by students like you, and our team of scholars
            provides insights on how best to answer the questions.
          </p>
        </div>
        <div className="w-[80%] lg:w-[45%] flex flex-col gap-4 lg:gap-0">
          <div className="w-full  lg:h-[13rem]  flex flex-col lg:flex-row lg:gap-4">
            <div className="lg:w-[50%] w-full  h-[13rem] lg:h-full">
              <AcademicCard
                className={"bg-blue text-white rounded-tl-md rounded-tr-md"}
                heading={"Optimize your reading"}
                content={
                  "With tailored content on how to learn efficiently, we reduce the time you spend learning"
                }
              />
            </div>
            <div className="lg:w-[50%] w-full h-full hidden"></div>
          </div>
          <div className="w-full  lg:h-[13rem] flex flex-col lg:flex-row gap-4 lg:gap-0">
            <div className="lg:w-[50%] w-full h-[13rem] lg:h-full">
              <AcademicCard
                className={
                  "bg-white text-black rounded-bl-md rounded-br-md shadow-md"
                }
                heading={"In-depth tutorials"}
                content={"We go the extra mile that your lecturer wouldn’t"}
              />
            </div>
            <div className="lg:w-[50%] w-full h-[13rem] lg:h-full">
              <AcademicCard
                className={"bg-blue text-white rounded-tr-md rounded-br-md"}
                heading={"Get rid of examination tension"}
                content={"With our mock exams, say goodbye to exam tension"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAcademic;
