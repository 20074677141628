import { RoundedBtn } from "../ui";
import Tutcov from "../../assets/images/Tutcov.svg";
import { levels } from "../../data";
import { useLocation, useNavigate } from "react-router-dom";
const LevelForumList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const faculty = location.state?.faculty ? location.state?.faculty : "";

  const handleOnClick = (level) => {
    navigate(`/forum/${level}`, { state: { faculty: faculty } });
  };

  return (
    <section className="h-screen w-screen flex flex-col gap-2">
      <div className="py-[1.2rem] h-[7vh] flex items-center justify-center border-b-2 border-b-blue">
        <img src={Tutcov} alt="Tutcov logo" srcset="" className="h-[2.5rem] w-auto" />
      </div>
      <div className="h-[92vh] w-full flex flex-col items-center justify-center p-4">
        <div className="w-full lg:w-fit rounded-[1.2rem] p-[2.6rem] flex flex-col gap-4 lg:gap-[2rem] items-center bg-white border-2">
          <div className="flex flex-col items-center">
            <h4 className="text-[1.5rem] font-[700] lg:text-[2.25rem] ">
              Choose Level
            </h4>
            <p className="text-gray-500">Please select your level</p>
          </div>
          <div className="flex flex-col items-center gap-2 ">
            {levels.map((level) => (
              <RoundedBtn
                name={`${level} level`}
                className={"bg-blue text-white"}
                onClick={() => handleOnClick(level)}
              />
            ))}
          </div>
          <p className="text-gray-500">
            Not to worry, you can change selected option later
          </p>
        </div>
      </div>
    </section>
  );
};

export default LevelForumList;
