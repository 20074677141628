import React from 'react'
import { LiaTimesSolid } from "react-icons/lia";
import {useDispatch} from 'react-redux';
import {forumActions} from '../../store/forumSlice';

const Modal = () => {
	const dispatch = useDispatch();
    const handleOnClick = () => {
        dispatch(forumActions.setCloseModal())
    }
	return (
		<div className="absolute inset-0 mobileModalBg modalBg w-screen h-screen flex justify-center items-center z-[999]">
			<div className="bg-pattern flex justify-center items-center p-10 md:p-16">
				<div className="flex flex-col gap-2 px-4 md:px-8 py-8 bg-[#FBFBFB] rounded-[10.7px] md:rounded-[32px] border-[#98A2B3] border-[1px] w-full md:w-2/3 md:ml-4">
					<LiaTimesSolid onClick={handleOnClick} className="self-end text-xl cursor-pointer font-bold" />
					<h2 className=" text-center text-[#101928] font-Montserrat font-bold text-lg md:text-4xl mb-4 ">Create a Thread</h2>
					<div className="flex flex-col justify-center gap-4 md:gap-6">
						<h3 className="text-base md:text-xl font-bold text-[#101828] font-inter">Thread Name</h3>
						<input className="placeholder:text-[#667085] text-[#28374B] w-full px-3 py-3 rounded-[9.44px] md:rounded-[30px] border-[1px] border-[#101828] bg-[#F2F4F7]" placeholder="# e.g Plant-mechanics" type="text"/>
						<p className="font-inter text-center text-base md:text-xl text-[#28374B] "
						>Threads are where people connect and chat about specific topics. We recommend using a name that is straightforward, so everyone can easily participate and join in on the conversation.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Modal