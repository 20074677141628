import React from 'react'
import {useNavigate} from 'react-router-dom'
import { useDispatch} from 'react-redux';
import {questionActions} from '../../store/questionSlice';

const QuestionModal = ({openModal, question}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleCloseModal = () => {
		dispatch(questionActions.setOpenModalFalse());
	}
	
	const handleSubmit = () => {
		console.log(question)
		dispatch(questionActions.addQuestion(question));
		navigate('/dashboard/022/grade')
	}
	return (
		<div className="gradBg fixed inset-0 w-full h-full flex justify-center items-center p-6 md:p-12 ">
			<div className="bg-white rounded-[32px] w-full md:w-[650px] lg:w-[700px] p-4 py-10 md:p-12  md:px-16 flex flex-col justify-center text-center gap-6">
				<h2 className="font-bold font-Montserrat text-xl md:text-4xl text-[#101928]">Double-check your answers</h2>
				<p className="text-base md:text-xl font-normal font-inter text-[#28374B]">Before submitting, take a final moment to review your answers and ensure you're satisfied with your selections.</p>
				<div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-0 px-4">
					<button onClick={handleCloseModal}  className="font-Montserrat font-semibold text-base text-[#0B0B0B] border-[2px] border-[#D92D20] bg-[#FEF3F2] w-[226px] lg:w-[246.5px] h-[51px] rounded-[50px]">Cancel</button>
					<button onClick={handleSubmit} className="font-Montserrat font-semibold text-base text-white bg-[#0066FF] w-[226px] lg:w-[246.5px] h-[51px] rounded-[50px]">Submit Test</button>
				</div>
			</div>
		</div>
	)
}

export default QuestionModal