import React, { useState } from 'react'

import { LuMail } from "react-icons/lu";
import { BiHide } from "react-icons/bi";
import { MdVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom"
import {useDispatch} from 'react-redux';
import {authActions} from '../../store/authSlice';
import axios from 'axios'
//import useAuth from '../../hooks/useAuth';



const Login = () => {
  //const { setAuth } = useAuth();
  const dispatch = useDispatch();
  const [Passwordvisib,setPasswordvisib] = useState(false)
  const [logins,setLogins] = useState({
    
    email :  " ",
    password: " ",
    rememberMe:false,
  })

  const url = 'https://tutcov-backend.onrender.com/accounts/login/'

  const updateLogins=(e)=>{
    const {name,value,checked,type} = e.target
        setLogins(prev=>{
          return {...prev,[name]: type === "checkbox" ? checked :value }
        })
        }
        //console.log(logins)

  const handleLogin = () => {
    axios.post(url, {
      email:logins.email,
      password: logins.password
    }, {
      headers:{
        "Content-Type": "application/json"
      }
    })
    .then((res) => {
        //console.log(res.data.access_token);
        dispatch(authActions.addaccess(res.data.access_token))
        //const accessToken = res?.data?.access_token;
        //setAuth({ accessToken });
        navigate('/dashboard')
    }).catch((error) => {
        //console.error(error)
        //alert("unable to log in , try again")
      })
  }
  
  let navigate = useNavigate()
  return (
    <div className='pt-[100px] '>
    <div className='border-[#D0D5DD] py-4 bg-white border-[1px] w-[80%] mx-auto font-[Montserrat] rounded-[10px] md:w-[25%]'>
   <div className='space-y-3'>
    <div> 
    <p className='font-bold text-2xl text-center'>Log in</p>
    <p className='text-[#667185] font-medium text-[11px] text-center'>Enter your credentials to access your account</p>
    </div>
    <div>
    <label>
      <span className='text-[#101928] text-[12px] pl-5'>EMAIL ADDRESS </span>
      <div className='w-[90%] flex mx-auto relative'>
      <input type="text" placeholder='Enter Email' name="email" onChange={updateLogins} value={logins.email}
      className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2'  />
      <span className='absolute right-2 top-2 text-[#D0D5DD] text-sm'><LuMail /></span>
      </div>


   </label>

    </div>
    <div >
    <label>
      <span className='text-[#101928] text-[12px] pl-5'>PASSWORD</span>
      <div className=' w-[90%] flex mx-auto relative'>
     <input type={Passwordvisib ? 'text': 'Password'}  name="password" onChange={updateLogins} value={logins.password}
     className='border-[1px] border-[#D0D5DD] focus:border-[#407BFF] rounded-[10px] outline-none text-[10px] w-[100%] p-2' />
      <span className='absolute right-2 top-2 text-[#D0D5DD] text-sm'>{Passwordvisib ?<MdVisibility onClick={()=>{setPasswordvisib(!Passwordvisib)}} /> : <BiHide onClick={()=>{setPasswordvisib(!Passwordvisib)}} />}  </span>
      </div> 
    </label>
    </div>

    <div className='flex w-[95%] justify-between mx-auto '>
      <label>
      <input type="checkbox" name="rememberMe" onChange={updateLogins} checked={logins.rememberMe} />
      <span className='text-[12px] font-semibold '>Remember me for 30 days</span>
      </label>
      <button className='text-[12px] text-[#0066FF]  '>forgot Password?</button>

    </div>
    <div className="w-[90%] mx-auto">

      <button onClick={handleLogin} className='text-white bg-[#0066FF] w-[100%] py-2 rounded-[15px]'>Proceed</button>
    </div>
    <div>
      <p className='text-center font-semibold text-[12px] text-[#98A2BE]'>Dont have an account <span className="text-[#124DD1] cursor-pointer" onClick={()=>navigate("/signup")}>Create Account</span></p>
    </div>


   </div>
      


    </div>
    </div>
  )
}

export default Login