import {
  AboutAcademic,
  AboutHero,
  AboutService,
  AboutStory,
  AboutTeam,
} from "../components/about";
import { AllRating } from "../components/common";
import { SecureSection, SecureSectionMobile } from "../components/home";

const AboutUs = () => {
  return (
    <>
      <AboutHero />
      <AboutStory />
      <AllRating />
      <AboutTeam />
      <AboutService />
      <AboutAcademic />
      <SecureSectionMobile />
      <SecureSection />
    </>
  );
};

export default AboutUs;
