import { teamData } from "../../data";
import { RoundedBtn } from "../ui";

const Team = ({ image, name, role }) => {
  return (
    <div className="flex flex-col w-[231px] lg:w-[14.5rem] ">
      <div className="w-[231px] lg:w-full h-[15.5rem] object-center object-contain">
        <img src={image} alt="" className="h-full w-full " />
      </div>
      <div className="py-2 flex flex-col gap-2">
        <p className=" text-[1.2rem] lg:text-[1.8rem] font-[600]">{name}</p>
        <p className="text-xs lg:text-base">{role}</p>
      </div>
    </div>
  );
};

const AboutTeam = () => {
  return (
    <section className="w-full p-4 lg:py-[2rem] flex flex-col items-center">
      <div className="w-[90%] flex flex-col gap-4">
        <div className="flex flex-col">
          <h2 className="text-[1.5rem] text-blue font-[700]">The Team</h2>
          <h3 className="text-[2.25rem] font-[700]">Meet the Team</h3>
          <p className="lg:w-[60%] lg:text-[1.2rem]">
            Behind TUTCOV is a dedicated team of educators, technologists, and
            experts who share a deep commitment to transforming the learning
            arena and level the playing field for all students.
          </p>
        </div>
        <div className="flex gap-4  lg:justify-between overflow-hidden">
          {teamData.map((team) => (
            <Team
              key={team.name}
              image={team.image}
              name={team.name}
              role={team.role}
            />
          ))}
        </div>
        <div className=" w-[50%] lg:w-[20%]">
          <RoundedBtn
            name={"See All"}
            className={"border-blue border text-xs lg:text-base text-blue"}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutTeam;
