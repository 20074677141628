import { RoundedBtn } from "../ui"

function SecureMobile (){
    return(
        <section className="md:hidden">
            <div className="bg-[#EAECF0] w-[90%] m-auto rounded-2xl   flex justify-center font-Montserrat py-7 mt-8">
                <div className=" w-[80%] m-auto flex flex-col space-y-4 text-left" >
                    {/* secure intro */}
                    <div className=" flex flex-col space-y-4">
                        <h3 className="font-[600] text-2xl">Secure Your Academic career using Tutcov</h3>
                        <p>Join over 2000+ students using our software and be at 
                            the top of your game for the next academic session</p>

                    </div>
                    {/*secure intro */}
                    <div className="flex flex-col space-y-2">
                    <RoundedBtn name="Get Started"
            className="w-70 bg-button-color text-white font-[500] " />
               <RoundedBtn name="Join Our Community"
            className="w-70 bg-[#FBFBFB] text-black font-[500]  border border-[#475467]" />

                    </div>
                </div>
            </div>
        </section>
    )

}
export default SecureMobile