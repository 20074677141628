//import { Mainlayout } from "./components/layout";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import Course from "./pages/Course";
import ForumRoom from "./pages/ForumRoom";
import ForumChatRoom from "./pages/ForumChatRoom";
import Dashboard from './pages/dashboard/Dashboard';
import DashboardHome from './pages/dashboard/DashboardHome';
import Courses from './pages/dashboard/Courses';
import Files from './pages/dashboard/Files';
import Settings from './pages/dashboard/Settings';
import LandingPage from './pages/LandingPage';
import Question from './pages/dashboard/Question'; 
import GradePage from './pages/dashboard/GradePage';
import Review from './pages/dashboard/Review'; 
import Auth from "./pages/auth/Auth";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import EmailVef from "./pages/auth/EmailVef";
import Forum from './pages/forum/Forum';
import TestingPage from './pages/TestingPage'; 


function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<LandingPage />}>
          <Route path="/" element={<Home />} />
          <Route path="/course" element={<Course />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/ContactUs" element={ <ContactUs/> } />
          <Route path="/forum" element={<Forum/>}/>
          <Route path='/test' element={<TestingPage/>}/>
        </Route>

        <Route element={<Auth/>}>
          
        <Route path ="/login" element ={<Login/>} />
        <Route path ="/signup" element ={<SignUp/>} />
        <Route path ="/VefEmail" element ={<EmailVef/>} />
        
     </Route>

        <Route element={<Dashboard/>} >
          
          <Route path="/dashboard" element={<DashboardHome/>}/>
          <Route path="/dashboard/courses" element={<Courses/>}/>
          <Route path="/dashboard/files" element={<Files/>}/>
          <Route path="/dashboard/settings" element={<Settings/>}/>    
        </Route>

        <Route path="/forum/room" element={<ForumRoom />} />
        <Route path="/forum/:level" element={<ForumChatRoom />} />
        <Route path="/dashboard/:question_id" element={<Question/>}/>
        <Route path="/dashboard/:question_id/grade" element={<GradePage/>} />
        <Route path="/dashboard/:question_id/:review_id" element={<Review/>}/>
      </Routes>
    </div>
  );
}

export default App;
