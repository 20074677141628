import { HiOutlineBars4 } from "react-icons/hi2";
import image from "../../assets/images/Image.png";

const MobileForumHeader = ({ slideIn, onSlide }) => {
  return (
    <nav className="lg:hidden w-full px-4 py-2 flex justify-between items-center fixed z-[5] top-0 left-0 bg-white">
      <button onClick={() => onSlide(true)}>
        <HiOutlineBars4 size={30} />
      </button>

      <img className="w-10 h-10 rounded-full" src={image} alt="pic" />
    </nav>
  );
};

export default MobileForumHeader;
