import React, {useState, useEffect} from 'react'
import Header from '../../components/dashboard/Header'
import MobileHeader from '../../components/dashboard/MobileHeader'
import DashInfo from '../../components/dashboard/DashInfo'
import Course from '../../components/dashboard/Course'
import AllCourses from '../../components/dashboard/AllCourses'
import {useSelector} from 'react-redux';
import axios from 'axios'
//import useAuth from "../../hooks/useAuth";

const Home = () => {
	const access = useSelector(state => state.auth.auth)
	//const [profile, setProfile] = useState();
	const [courses, setCourses] = useState([])

	const url = 'https://tutcov-backend.onrender.com/my-courses/'
	//const profileUrl = 'http://tutcov-backend.onrender.com/accounts/me/'
	//const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE3MTk4NzkwLCJpYXQiOjE3MTcxOTY5OTAsImp0aSI6IjdkODMyYmY4ZDgzNDRlN2I5YWU3ODU5NTM4ZTYzZDIyIiwidXNlcl9pZCI6MX0.YGlytRgZJdnkYPhU09ex6qOmEEjSjZHE5pgBTRGH2hw'

	useEffect(() => {
		const fetchProfile = () => {
			axios.get(url, {
				headers:{
				"Content-Type": "application/json",
				"Authorization": `Bearer ${access}`
				}
			})
				.then((res) => {
				console.log(res.data.enrollments);
				setCourses(res.data.enrollments)
			});
		}
		fetchProfile()
	}, [access])
	return (
		<div>
		    <MobileHeader/>
			<Header title="Home"/>
			<DashInfo numberOfCourses={courses.length}/>
			<Course courses={courses}/>
			<AllCourses/>
		</div>
	)
}

export default Home