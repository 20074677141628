import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import { motion } from "framer-motion";
import Tutcov from '../assets/images/Tutcov.svg'; 
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom"




const Navbar = () => {
	let navigate = useNavigate()

	const [closeMobile, setCloseMobile] = useState(false);

	const variants = {
		open: { opacity: 1, x: 0 },
		closed: { opacity: 0, x: "-100%" },
	}

	const navLinkStyles = ({isActive}) => {
		return{
			borderBottom: isActive? "4px solid #0066FF" : "",
			color: isActive? "#0066FF" : ""
		}
	}


	return (
		<nav className=" w-screen h-20 md:h-24 sticky top-0 left-0 bg-white z-50 ">
		    <div className="flex flex-col w-full lg:hidden">
		    	<div className="flex justify-between items-center p-4 pl-8 pr-8 bg-white cursor-pointer">
		    		<NavLink to="/"><img src={Tutcov} className="h-16 w-auto " alt="tutcov"/></NavLink>
		    		{ closeMobile ? <AiOutlineClose onClick={() => setCloseMobile(false)} className="cursor-pointer text-[27px]" /> : <AiOutlineMenu onClick={() => setCloseMobile(true)} className="cursor-pointer text-[27px]"/>}
		    	</div>
		    	<motion.div
				    animate={closeMobile ? "open" : "closed"}
                    variants={variants}
                    className="w-full "
				>
					<ul className={`flex flex-col gap-10 p-12 bg-white w-full h-screen font-montserrat font-medium text-[#28374B] text-lg ${closeMobile ? " ": "hidden" }`}>
						<NavLink to="/" onClick={()=>setCloseMobile(false)} className="hover:text-[#0066FF] hover:text-xl ">Home</NavLink>
						<NavLink to="/about" onClick={()=>setCloseMobile(false)} className="hover:text-[#0066FF] hover:text-xl" >About Us</NavLink>
						<NavLink to="/course" onClick={()=>setCloseMobile(false)} className="hover:text-[#0066FF] hover:text-xl">Courses</NavLink>
						<NavLink to="/forum"  onClick={()=>setCloseMobile(false)} className="hover:text-[#0066FF] hover:text-xl">Forum</NavLink>
						<NavLink to="/login"  onClick={()=>setCloseMobile(false)} className="hover:text-[#0066FF] hover:text-xl">Log in</NavLink>
						<NavLink to="/signup"  onClick={()=>setCloseMobile(false)} className="hover:text-[#0066FF] hover:text-xl">Create Account</NavLink>
					</ul>
				</motion.div>
		    </div>
		    <div className="hidden lg:flex justify-between h-24 items-center p-6 pl-12 pr-12 ">
		    	<NavLink to="/" className="w-[18rem] h-20 p-3 cursor-pointer">
		    		<img className=" w-auto " src={Tutcov} alt="tutcov"/>
		    	</NavLink>
		    	<ul className="flex justify-center items-center gap-16 bg-white w-full font-montserrat font-medium text-[#28374B] text-lg">
		    		<NavLink className="hover:text-[#0066FF] hover:scale-125 " style={navLinkStyles} to="/">Home</NavLink>
					<NavLink className="hover:text-[#0066FF] hover:scale-125" style={navLinkStyles} to="/about">About Us</NavLink>
					<NavLink className="hover:text-[#0066FF] hover:scale-125" style={navLinkStyles} to="/course">Courses</NavLink>
					<NavLink className="hover:text-[#0066FF] hover:scale-125" style={navLinkStyles} to="/forum">Forum</NavLink>
		    	</ul>
		    	<div className="flex justify-center items-center font-montserrat text-lg font-semibold">
		    		<button className="w-[7rem] h-[3rem] hover:text-[#0066FF] hover:text-xl" onClick={()=>navigate("/login")}>Log in</button>
		    		<button onClick={()=>navigate("/signup")} className="w-[11rem] h-[3.2rem] bg-[#0066FF] rounded-[30px] text-white hover:text-[#0066FF] hover:bg-white hover:border-2 hover:border-[#0066FF]">Create Account</button>
		    	</div>
		    </div>
		</nav>
	)
}

export default Navbar;