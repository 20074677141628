import React from 'react'
import freelancerimg from "../../assets/images/freelancer.png";

const Help = () => {
  return (
    <div className='mb-4'>
      <div className='w-[90%]  m-auto mt-4 font-[Montserrat] mb-3 text-[#0B0B0B]'>
        <h1 className=' text-2xl font-bold flex flex-col space-y-2 text-center mb-5 md:text-left md:text-[64px] md:font-semibold md:space-y-10 md:mb-8 md:max-w-[70%]'>
        <span>Let’s help you navigate</span> 
      <span>your learning journey</span>
      </h1>
        <p className='font-medium text-lg text-center md:text-left md:max-w-[70%]  md:text-xl' >Ace your tests and improve your grades with our one-on-one tutoring, get the help you need to succeed in school and beyond.</p>
      </div>
      <div className=' w-full h-[25rem]  md:h-[50rem] '>
      <img src={freelancerimg} alt="" className=' w-full h-full '/>
        
      </div>
      
      
    </div>
  )
}

export default Help
