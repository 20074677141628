import Trusted from "../../assets/images/trustedmaterial.png";
import QuestionAnswer from "../../assets/images/questionanswer.png";
import Curriculum from "../../assets/images/curriculum.png";
function OfferSection() {
  return (
    <section className="w-full bg-white flex flex-col items-center justify-center py-[3rem] relative">
      <div className="w-[90%]">
        <div className="w-full monserate_font lg:w-[45%]">
          <h2 className="text-[1.5rem] lg:text-[3rem] font-[600] lg:font-[700]">
            What we offer
          </h2>
          <p className="lg:text-[1.125rem]">
            We give you the best trusted materials for free and help you to
            structurally perform well in every semester
          </p>
        </div>
        <div className="flex flex-col mt-4 lg:flex-row">
          <div className="h-[22.5rem]  lg:w-[33.3%] w-full">
            <img
              src={Trusted}
              alt="Trusted Materials"
              className="h-full w-full"
            />
          </div>
          <div className="h-[22.5rem]  lg:w-[33.3%] w-full">
            <img
              src={QuestionAnswer}
              alt="Question and Answer"
              className="h-full w-full"
            />
          </div>
          <div className="h-[22.5rem]  lg:w-[33.3%] w-full">
            <img src={Curriculum} alt="Curriculum" className="h-full w-full" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferSection;
