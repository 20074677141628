import { RoundedBtn } from "../ui";
import { useNavigate } from "react-router-dom"

function Secure() {
  let navigate = useNavigate()
  
  return (
    <section className=" hidden md:block">
      <div className=" w-full h-[40rem] bg-low_img bg-cover bg-center my-10  relative">
        <div className=" w-1/2 pr-10 flex flex-col space-y-4 text-right  text-white monserate_font  pt-5 absolute  right-4 h-full justify-center">
          <h2 className=" font-bold text-[4rem]  space-y-4">
            Ready to embark on your <br/>learning journey?
          </h2>
          <p className="text-[1.2rem] font-[400]  ">
            Sign up now and be part of a community of TUTCOVites that nurtures growth, curiosity, and success. Your future starts today as you sign up below.
          </p>
          <RoundedBtn
            onClick={() => navigate('./signup')}
            name="Sign up"
            className="w-60 bg-button-color text-white font-[600] self-end"
          />
        </div>
      </div>
    </section>
  );
}
export default Secure;
