import { BsSend } from "react-icons/bs";
import { ImAttachment } from "react-icons/im";

const TypeMessage = () => {
  return (
    <form className="rounded-[1.3rem] w-full flex gap-2 bg-gray-100 border-2 p-2">
      <button>
        <ImAttachment size={20} />
      </button>
      <input
        type="text"
        className="w-full h-full bg-gray-100 focus:outline-none"
        placeholder="Type your message"
      />
      <button>
        <BsSend size={20} />
      </button>
    </form>
  );
};

export default TypeMessage;
