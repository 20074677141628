import { Link } from "react-router-dom";

const ForumPost = () => {
  return (
    <div
      className="w-full flex flex-col gap-8 justify-between"
    >
      <div className="flex flex-col gap-4  ">
        <p className="font-semibold">Grace J.</p>
        <p>
          Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
          turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
          fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
          elit sed risus. Maecenas eget condimentum velit, sit amet feugiat
          lectus.
        </p>
      </div>
      <div>
        <hr className="my-2" />
        <div className=" flex gap-4 items-center">
          <p className="px-4 py-2 bg-gray-200 rounded-md w-fit text-xs">
            20:15 pm
          </p>
          <Link
            to={`/forum`}
            state={{ postId: 1 }}
            className="text-gray-500 text-xs"
          >
            2 replies
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForumPost;
