import {createSlice} from '@reduxjs/toolkit';

const sideBarSlice = createSlice({
	name: "sideBar",
	initialState: {
		openMobile: false,
	},
	reducers:{
		setOpenMobiletrue(state){
			state.openMobile = true;
		},
		setOpenMobilefalse(state){
			state.openMobile = false;
		},
	}
})

export const sideBarActions = sideBarSlice.actions;
export default sideBarSlice;