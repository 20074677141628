import React from 'react';

const AllCourses = () => {
	let list = new Array(35);
	list.fill(12);
	return (
		<div className="mt-12 p-8">
			<h3 className="font-Montserrat font-medium text-black text-lg">Faculty courses | Course code</h3>
			<div className="flex flex-wrap justify-center items-center gap-6 w-full pt-8">
				{list.map((item,i) => 
					<p key={i} className="font-Montserrat font-medium text-black text-base bg-white border-[#E6E6E6] border-[1px] w-fit p-1 pl-3 pr-3">GEG</p>
			    )}
			</div>
		</div>
	)
}

export default AllCourses;