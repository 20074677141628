import React from 'react'
import {Outlet} from 'react-router-dom';
import Tutcov from "../../assets/images/Tutcov.svg"


const Auth = () => {
  return (
    <div className=''>
      <div className='flex justify-center items-center fixed w-screen h-16  bg-white z-50 shadow-sm  border-b-[2px] border-b-[#0066FF]'>
      <img src={Tutcov} alt="tutcov" className="h-[2.5rem]"/>
      </div>
      <div className=' bg-pattern bg-center bg-no-repeat w-full bg-[#F7F9FC] h-[100vh] py-12'>

        <Outlet/>
        </div>

    </div>
  )
}

export default Auth