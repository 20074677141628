import React from 'react';
import Box2 from '../../assets/images/Box2.png'
import {Link, useNavigate} from 'react-router-dom'

const Modal = ({course, setOpenModal}) => {
	const navigate = useNavigate();

	return (
		<div onClick={() => setOpenModal(false)} className="bg-modal-background fixed top-0 bottom-0 left-0 right-0 w-full p-12 md:p-0 md:pl-24 h-full flex justify-center items-center">
			<div className="bg-white rounded-[10px] p-6">
				<img src={Box2} className="" alt="card"/>
				<div className="mt-8">
					<h2 className="text-black text-lg font-Montserrat font-bold mb-4">{`${course.course} (${course.courseCode})`}</h2>
					<div className="grid grid-cols-4 gap-4 mb-8 w-72">
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2015</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2016</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2017</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2018</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2019</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2020</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2021</Link>
						<Link to="/dashboard/022" className="font-Montserrat font-semibold text-sm text-black cursor-pointer hover:text-[#0066FF] hover:font-bold">2022</Link>
					</div>
					<button onClick={() => {setOpenModal(false); navigate('/dashboard/022');}} className="font-Montserrat font-semibold text-base text-white bg-[#0066FF] w-48 h-12 rounded-[50px]">Attempt questions</button>
				</div>
			</div>
		</div>
	)
}

export default Modal;