import { LadyImage, CourseDetais, Buttons } from "../components/Courses";

const Course = () => {
  return (
    <div>
      <LadyImage />
      <Buttons />
      <CourseDetais />
    </div>
  );
};

export default Course;
