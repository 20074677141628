function RoundedBtn({ name, className, onClick }) {
  return (
    <button
      onClick={onClick}
      className={` text-center py-[.75rem] px-[3rem] rounded-[2.5rem] ${className} `}
    >
      {name}
    </button>
  );
}

export default RoundedBtn;
