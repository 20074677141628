import React from 'react'
import { useState} from "react"

const AllFaculties =[
  {faculty:" All", id:1, isActive:false },
  {faculty:" Arts", id:2, isActive:false },
  {faculty:" Engineering", id:3, isActive:false },
  {faculty:" Science", id:4, isActive:false}
]


const Buttons=({text,id,changeActive,isActive})=>{

  const style =
  {
    backgroundColor:isActive ? "blue" : "  ",
    color:isActive ? "white" : "  "
    
    
  }
  

    return(
      <div>
        <button
        onClick ={()=>{changeActive(id)}}
        style={style}
   className='border-[1px] border-[#00000033] font[montserrat] font-medium  text-[#000000] md:text-[#00000099] text-[12px] rounded-[10px]  px-[18px] md:px-[30px] py-[10px] md:border-none md:text-[20px] md:font-[inter]'>
    {text} 
   </button>
      </div>
    )
  }

const ButtonComponent = () => {

  const [Faculties,setFaculties] = useState(AllFaculties)
  console.log(Faculties)


  const changeActive=(id)=>{
    setFaculties(prev=>prev.map(faculty=>{
      return(faculty.id=== id ? {...faculty, isActive:true}:{...faculty,isActive:false})
    }))
  }
  return (
    <div className='flex justify-around w-full  mb-[5rem]  md:w-[70%] md:mx-auto md:justify-between md:mb-3'>

        {Faculties.map(faculty=>{
          return (
            <Buttons text ={faculty.faculty} key ={faculty.id}  changeActive={changeActive} id={faculty.id} isActive={faculty.isActive} />
          )
         } )}
      
    </div>
  )
}

export default ButtonComponent
