import { serviceData } from "../../data";

const ServiceCard = ({ icon, bgIcon, content }) => {
  return (
    <div className="w-full lg:w-[32%] flex flex-col gap-4 items-center p-4">
      <div
        style={{
          backgroundColor: bgIcon,
        }}
        className={`lg:w-[5rem] lg:h-[5rem] w-[3.5rem] h-[3.5rem] rounded-md p-4`}
      >
        <img src={icon} alt="" className="w-full h-full" />
      </div>
      <div className="h-[5rem]">
        <p className="text-center">{content}</p>
      </div>
    </div>
  );
};

const AboutService = () => {
  return (
    <section className="w-full p-4 lg:py-[2rem] flex flex-col items-center">
      <div className="w-full md:w-[90%] flex flex-col items-center  gap-4 lg:gap-6">
        <h2 className="text-[1.5rem] font-[700] lg:text-[2.25rem]">
          Services We Offer
        </h2>
        <div className="w-full lg:w-[90%] flex flex-col lg:flex-row items-center">
          {serviceData.map((service) => (
            <ServiceCard
              key={service.bgIcon}
              bgIcon={service.bgIcon}
              icon={service.icon}
              content={service.content}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutService;
