import { Link } from "react-router-dom";
import Tutcov from "../assets/images/Tutcov.svg";
import { FaFacebook } from "react-icons/fa";
import {AiFillTwitterCircle} from "react-icons/ai"
import {AiFillInstagram} from "react-icons/ai"
import {AiFillYoutube} from "react-icons/ai"




function Footer(){
      const style= {width:"100%",height:"100%"}
    
return(
    
        <footer>
<div className="font-[Montserrat]  my-5 space-y-4 border-t-[1px]  border-t-[#DOD5DD] pt-3  md:w-[90%] md:m-auto md:flex   md:my-[3rem] md:justify-between md:border-none">
        <div className=" flex  w-[90%] m-auto justify-between text-[0.9rem] pb-4 md:w-[50%] md:order-[-1] border-b-[1px]  md:border-none md:justify-normal md:space-x-6 md:pl-6">
                <div className="flex flex-col space-y-2 text-left  md:text-[#0066FF] ">
                <Link to={"/"} className=" ">
          <span className="  font-semibold  md:font-bold text-sm md:text-lg text-[#28374B] md:text-black block md:mb-3">Categories</span>
                </Link>
                <Link to={"/course"} className="">
          <span className=" font-semibold text-xs  font-[Montserrat]">Courses</span>
                </Link>
                <Link to={"/"} className="">
          <span className=" font-semibold text-xs  font-[Montserrat]">Past Questions</span>
                </Link>

                <Link to={"/"} className="">
          <span className=" font-semibold text-xs">Individual Development</span>
                </Link>
                <Link to={"/"} className="">
          <span className="font-semibold text-xs">Tutors</span>
                </Link>
 </div>

                <div className="flex flex-col space-y-2 text-[#0B0B0B] md:text-[#0066FF]">
                <Link to={"/"} className=" ">
          <span className="font-semibold  md:font-semibold text-sm md:text-lg  text-[#28374B] md:text-black block md:mb-3">Others</span>
                </Link>
                <Link to={"/"} className="">
          <span className="font-semibold text-xs ">Terms and Conditions</span>
                </Link>
                <Link to="/ContactUs" className="">
          <span className="font-semibold text-xs  ">Contact Us</span>
                </Link>
                <Link to={"/"} className="">
          <span className="font-semibold text-xs">About Us</span>
                </Link>
                <Link to={"/"} className="">
          <span className=" font-semibold  text-xs">Help Desk</span>
                </Link>  
                </div>
            </div>
            <div className="w-[90%] m-auto space-y-2 md:order-[0]  md:w-[30%] font-[Montserrat]" >
                <p className="text-[#0A033C] text-xs font-semibold">Stay up to date with the latest course</p>
                <div className=" space-x-3 ">
                <input type="Email" placeholder="Enter Your Email" 
            className=" border-[1px] border-[#939BA5] text-[#0B0B0B] rounded-[21px] text-center text-xs outline-none py-2 px-4 " 
        />
                
<button className=" px-3 bg-white  text-[#0066FF] font-semibold border-[#0066FF] text-[12px] border-[1px] py-[0.4rem]  rounded-[21px]">Subscribe</button>
                </div>
            </div>
        

            <div className="flex flex-col space-y-2 w-[90%] m-auto md:order-[-2] md:w-[15%]"> 
            

                <div className=" w-[70px] md:w-[100px] ">
                    <img src={Tutcov} alt="" className="w-full" />

                </div>
                <div className="flex space-x-3 ">
                  <div className="w-[23px] h-[23px] md:w-[30px] md:h-[30px]"><FaFacebook  style={style} /></div>
                    <div className="w-[23px] h-[23px] md:w-[30px] md:h-[30px]"><AiFillTwitterCircle style={style}/> </div>
                    <div className="w-[23px] h-[23px] md:w-[30px] md:h-[30px]"><AiFillInstagram style={style} /></div>
                    <div className="w-[23px] h-[23px] md:w-[30px] md:h-[30px]"><AiFillYoutube style={style} /></div>
 
                


                </div>

            </div>
            </div>

        </footer>
    
)


}

export default Footer