
import { Link, useParams } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import image from "../../assets/images/Image.png";
import signout from "../../assets/icons/sign-out.png";
import { LiaTimesSolid } from "react-icons/lia";
import {useDispatch} from 'react-redux';
import {forumActions} from '../../store/forumSlice';

const ForumSideNav = ({ slideIn, onSlide }) => {
  const params = useParams();
  const level = params.level;
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(forumActions.setOpenModal())
  }

  return (
    <nav
      className={`lg:block ${
        slideIn ? "block absolute top-0 left-0 z-[6]" : "hidden"
      } bg-dark-blue h-screen  py-2`}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div>
          <div className="flex justify-between  border-b-2 pr-4">
            <p className="text-[1.5rem] font-[700] text-white w-full px-8 py-2">
              {level} level
            </p>
            <button
              className={`${slideIn ? "block" : "hidden"}`}
              onClick={() => onSlide(false)}
            >
              <LiaTimesSolid size={30} className="text-white" />
            </button>
          </div>

          <ul className="w-full text-white ">
            <li className="px-6 py-2 border-b-2 w-full">
              <Link to={``} className="w-full block p-2  rounded-md">
                # General classes
              </Link>
            </li>
            <li className="w-full flex flex-col items-center">
              <Link
                to={``}
                className="w-full px-8 py-4 border-b-2 flex    justify-between"
              >
                <span className="font-semibold">GNS 101</span>{" "}
                <RiArrowDownSLine size={22} />
              </Link>
              <ul className="w-[90%] text-white ">
                <li className=" py-2 w-full">
                  <Link
                    to={``}
                    className="w-full px-5 py-2  flex justify-between rounded-md"
                  >
                    <span className=""># Pronunciation </span>{" "}
                    <span className="bg-white text-dark-blue py-1 px-2 rounded-md text-xs">
                      2
                    </span>{" "}
                  </Link>
                </li>
                <li className=" py-2 w-full">
                  <Link
                    to={``}
                    className="w-full px-5 py-2  flex justify-between rounded-md"
                  >
                    <span className=""># Literature </span>{" "}
                    {/* <span className="bg-white text-dark-blue py-1 px-2 rounded-md text-xs">
                    2
                  </span>{" "} */}
                  </Link>
                </li>
                <li className=" py-2 w-full">
                  <Link
                    to={``}
                    className="w-full px-5 py-2 bg-dark-blue-500 flex justify-between rounded-md"
                  >
                    <span className=""># Sentences </span>{" "}
                    {/* <span className="bg-white text-dark-blue py-1 px-2 rounded-md text-xs">
                    2
                  </span>{" "} */}
                  </Link>
                </li>
                <li className=" py-2 w-full">
                  <Link
                    to={``}
                    className="w-full px-5 py-2  flex justify-between rounded-md"
                  >
                    <span className=""># Time Management </span>{" "}
                    {/* <span className="bg-white text-dark-blue py-1 px-2 rounded-md text-xs">
                    2
                  </span>{" "} */}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-4">
          <div className="w-full px-4 flex flex-col items-center">
            <button onClick={handleOnClick} className="text-center w-full py-[.75rem] px-[.3rem] rounded-[2.5rem] justify-center items-center flex gap-3 bg-blue text-white">
              <span>Create Thread</span>
              <AiOutlinePlus />
            </button>
          </div>

          <div className="flex justify-between px-4 items-center gap-4 lg:gap-8 w-full ">
            <div className="w-[70%] flex items-center gap-4">
              <img className="w-10 h-10 rounded-full" src={image} alt="pic" />
              <div className="">
                <p className="text-white font-semibold font-inter text-sm">
                  John Doe
                </p>
                <p className="text-[#E3EFFC] text-xs font-inter">
                  johndoe@gmail.com
                </p>
              </div>
            </div>

            <button>
              <img src={signout} alt="logout" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default ForumSideNav;
