
import React, { useState } from 'react'
import CourseDetais  from './coursedetail.js'



const Course = ({Img,Title,Code,viewCourse,id,view,closeCourse})=>{
  console.log(view)



  return (
    <div className= "   w-[80%] mx-auto  md:w-[30%]   mb-[6rem]  " >
    
  <div className=" bg-[#F6F6F6] w-full mb-[2rem] font-medium   space-y-2 pb-2   font-[Montserrat] md:h-[530px] " >
    <div className=' w-full h-[40%] md:h-[300px]'>
    <img src= {Img} alt="" className=' w-full h-full px-1 rounded-[10px]'/>
    </div>
    <h3 className='text-[16px] md:text-[22px] px-5'> {Title}</h3>
    <p className=' text-[ #292D32] text-[16px] md:text-[20px] px-5'>{Code}</p>
    <p className='text-[14px] font-normal text-[#000000B2] md:text-[18px] px-5'>Introduction to Limit and continuity, Differentiation and Methods of differentiation</p>
    
    
    <button className=' text-[14px]  md:text-[18px] px-5 text-[#0066FF]' onClick={()=>{viewCourse(id)}} > View Course</button>

  </div>
  {view && 
  
  
  <div className='w-full h-full  fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-modal-bg'>
  <div 
    className=' max-h-[490px] font-bold text-[15px] space-y-4 md:mt-[50px]  px-2 md:px-4 pb-3  md:pb-5  
     bg-white w-[85%] pt-3 rounded-[10px]   md:w-[50vw]  
      shadow-[7px_8px_4px_0px_#00000040]  ' >
      <div className='w-full h-[150px]'  >
    <img src= {Img}  alt="" className='w-full h-full'/>
    </div>
    <p className='md:text-[24px]'>
      <span className='block md:inline' >Course Title: {Title} </span>
      <span>({Code})</span></p>
  
      

      <p className='text-[14px] space-y-3'>
        <span className='block md:text-[20px]'>Description</span>
        <span className='font-semibold text-xs md:text-[14px] '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Quisque vestibulum congue erat. Mauris 
      fringilla accumsan neque, sed molestie sapien suscipit ac. 
      Aliquam sit amet vehicula est. Donec a.</span>
        

      </p>
      <div className=" text-[#0066FF] flex justify-between px-2 ">
      <button className='text-white  font-semibold text-[10px] bg-[#0066FF] rounded-[4px] p-3 md:text-[15px]'>Attempt question</button>
      <button className=' text-[16px] md:text-[20px]' onClick={closeCourse}> close</button>
      </div>

      </div>
    </div> }

  
  </div>

  )

}

const AllCourses = () => {
  const [courses,setCourses] = useState(CourseDetais)
  console.log(courses)

  const viewCourse = (ID)=>{
    setCourses(prev=>prev.map(course=>{
      return (course.id===ID ?{...course,view:true} :{...course,view:false} )

    }))

  }
  const closeCourse= ()=>{
    setCourses(prev=>prev.map(course=>{ return  { ...course,view:false} }))
  }

  return (
    <div className='flex flex-col md:flex-row md:flex-wrap  md:relative '>
      {courses.map(course=>{
        return(
          
          <Course  {...course} viewCourse= {viewCourse}   closeCourse={closeCourse}/>
          
        )
      })}
      
    </div>
  )
}

export default AllCourses
