import { IoIosSearch } from "react-icons/io";

const SearchForm = () => {
  return (
    <form className="rounded-[1.3rem] w-full flex gap-2 bg-gray-100 border-2 p-2">
      <IoIosSearch size={25} />
      <input
        type="text"
        className="w-full h-full bg-gray-100 focus:outline-none"
        placeholder="Search preferred course"
      />
    </form>
  );
};

export default SearchForm;
