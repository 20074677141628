import { Link } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import { reviewData } from "../../data";

const StudentReview = ({ img, name, department, review, className, style }) => {
  return (
    <div
      style={style}
      className={`w-[400px]  border transition-transform  rounded-[1.2rem] p-4 bg-blue md:bg-gray-color ${className}`}
    >
      <div className=" m-auto font-Montserrat align-middle flex flex-col gap-2 ">
        <div className="w-[60px] h-[60px] hidden md:block">
          <img src={img} alt="" className="hidden md:block width-[100%] " />
        </div>
        <p className="text-sm font-bold order-4 md:order-[0] flex flex-col">
          <span>{name}</span>
          <span>{department}</span>
        </p>

        <div>
          <p className="text-[1.1rem] font-small">{review}</p>
        </div>
      </div>
    </div>
  );
};

function Students() {
  const [current, setCurrent] = useState(0);
  const reviewLength = reviewData.length;
  const steps = reviewLength - 3;
  
  let slideIndicatorWidth = 100 / steps;

  const moveRight = () => {
    if (current < steps) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };
  const moveLeft = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(0);
    }
  };

  return (
    <section className=" my-[5rem] space-y-2 md:flex flex-col gap-2 items-center">
      <div className="w-[90%] m-auto md:flex md:justify-between ">
        <div className=" font-Montserrat   flex flex-col space-y-3">
          <h3 className=" text-2xl font-semibold text-left md:text-5xl lg:font-bold">
            What Our Students Say
          </h3>
          <p className="hidden  w-[500px] font-normal text-xl lg:block">
            {" "}
            What our past students have to say about us. Take a look at the
            journeys of our former students
          </p>
        </div>
        <div className=" md:self-end">
          <Link to={"/"} className="text-blue flex items-center gap-2 w-fit">
            <span className=" font-normal ">See More Stories</span>
          </Link>
        </div>
      </div>
      {/*Review Container */}
      <div className=" hidden w-[90%] py-[1rem] gap-4 overflow-hidden md:flex flex-col  ">
        <div className="w-full flex gap-4 ">
          {reviewData.map((data, index) => (
            <div key={index} className="">
              <StudentReview
                name={data.name}
                department={data.department}
                img={data.image}
                review={data.review}
                style={{
                  transform: `translateX(-${current * 416}px)`,
                }}
              />
            </div>
          ))}
        </div>
        <div className="w-[300px] flex flex-col gap-2">
          <div className="w-[30%] flex gap-4 items-center">
            <button
              onClick={moveLeft}
              className="h-[1.5rem] w-[1.5rem] border flex items-center justify-center border-black rounded-[50%]"
            >
              <BsArrowLeft />
            </button>
            <button
              onClick={moveRight}
              className="h-[1.5rem] w-[1.5rem] border flex items-center justify-center border-black rounded-[50%]"
            >
              <BsArrowRight />
            </button>
          </div>
          <div className="w-full h-[5px] bg-gray-color rounded-lg">
            <span
              style={{
                transform: `translateX(${current * slideIndicatorWidth}%)`,
              }}
              className={`bg-blue w-[${slideIndicatorWidth}%] h-full rounded-lg block transition-transform`}
            ></span>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Students;
