import {createSlice} from '@reduxjs/toolkit';

const forumSlice = createSlice({
	name: "forum",
	initialState: {
		openModal: false,
	},
	reducers:{
		setOpenModal(state){
			state.openModal= true;
		},
		setCloseModal(state){
			state.openModal = false;
		},
	}
})

export const forumActions = forumSlice.actions;
export default forumSlice;