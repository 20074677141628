import React from 'react'
import {useNavigate} from 'react-router-dom';
import Vector1 from '../../assets/images/Vector1.svg'
import Vector2 from '../../assets/images/Vector2.svg'
import image5 from '../../assets/images/image5.png'

const Forum = () => {
	const navigate = useNavigate();
	return (
		<div className="bg-gradient-1 w-full h-full">
			<div className="bg-pattern flex flex-col">
				<div className="flex justify-between">
					<div className="text-white font-Montserrat w-full text-center md:text-left md:w-2/3 lg:w-1/2 md:ml-4 lg:ml-16 mt-4 md:mt-12 p-8  flex flex-col items-center md:items-start gap-4">
						<h2 className="font-bold text-3xl md:text-5xl ">Connect with peers <br/> and mentors</h2>
						<p className="font-medium text-base md:text-lg mb-6">Connect with fellow peers in a friendly and creative atmosphere. Share your thoughts, ask questions, and discuss on specific topics. Embark on a journey of knowledge exchange and collective wisdom. Let the conversations unfold!</p>
						<button onClick={() => navigate("/forum/room")} className="h-[3.75rem] w-64 bg-[#FBFBFB] text-[#0066FF] rounded-[40px] text-lg font-inter font-medium">Join your level’s forum</button>
					</div>
					<div className="hidden md:flex justify-between overflow-hidden ">
						<img src={Vector2} alt="vector" className=" -translate-y-12 lg:-translate-y-4 "/>
						<img src={Vector1} alt="vector" className="-translate-x-6 lg:translate-x-0 translate-y-44"/>
					</div>
				</div>
				<div className=" mt-8 md:mt-0 self-end">
					<img src={image5} alt="card"/>
				</div>
			</div>
		</div>
	)
}

export default Forum