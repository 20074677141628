import React from 'react'
import Box from '../../assets/images/Box.svg'
import Header from '../../components/dashboard/Header'
import MobileHeader from '../../components/dashboard/MobileHeader'

const Files = () => {
	return (
		<div >
		    <MobileHeader/>
		    <Header title="My files"/>
			<div className="p-8">
				<h2 className="text-black font-medium text-lg font-Montserrat text-center md:text-left">Your Downloads</h2>
				<div className="flex flex-col md:flex-row gap-6 md:gap-0 justify-between items-center flex-wrap pt-4">
					<div className=" w-[192px]" >
						<img src={Box} alt="card"/>
						<div className=" pt-4 pb-6">
							<p className="text-black text-sm font-Montserrat font-semibold">KA Stroud</p>
							<button className="text-[#475367] font-inter text-sm" >View</button>
						</div>
					</div>
					<div className=" w-[192px]" >
						<img src={Box} alt="card"/>
						<div className=" pt-4 pb-6">
							<p className="text-black text-sm font-Montserrat font-semibold">KA Stroud</p>
							<button className="text-[#475367] font-inter text-sm" >Add to files</button>
						</div>
					</div>
					<div className=" w-[192px]" >
						<img src={Box} alt="card"/>
						<div className=" pt-4 pb-6">
							<p className="text-black text-sm font-Montserrat font-semibold">KA Stroud</p>
							<button className="text-[#475367] font-inter text-sm" >Add to files</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Files