import React from 'react'
import {Link} from 'react-router-dom'
import Tutcov from '../../assets/icons/Tutcov.svg';
import icon from '../../assets/icons/icon.svg';
import file from '../../assets/icons/file.svg';
import icon1 from '../../assets/icons/icon1.svg';
import icon2 from '../../assets/icons/icon2.svg';
import settings from '../../assets/icons/settings.svg';
import book from '../../assets/icons/book.svg';
import image from '../../assets/images/Image.png';
import signout from '../../assets/icons/sign-out.png';
import { useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {sideBarActions} from '../../store/sideBarSlice';
import { motion } from "framer-motion";


const Sidebar = () => {
	const pathname = useLocation().pathname;
	const closeMobile = useSelector(state => state.sideBar.openMobile)
	const dispatch = useDispatch()
	const handleCloseMobile = () => {
		dispatch(sideBarActions.setOpenMobilefalse());
	}

	const variants = {
		open: { opacity: 1, x: 0 },
		closed: { opacity: 0, x: "-100%" },
	}

	return (
		<>
			<div className="hidden md:flex bg-[#08132A] w-[18rem] p-4 min-h-screen flex-col justify-between pb-8 sticky left-0 bottom-0 top-0 z-50">
			    <div>
					<div>
						<img className=" " src={Tutcov} alt="logo"/>
					</div>
					<ul className="flex flex-col items-start justify-center gap-3 mt-8 ">
						<Link to="/dashboard" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard' ? 'bg-[#667085]' : '' }`}>
							<img src={icon1} alt="icon"/>
							<p className="text-white text-sm font-Montserrat font-semibold">Home</p>
						</Link>
						<Link to="/dashboard/courses" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard/courses' ? 'bg-[#667085]' : '' }`}>
							<img src={book} alt="icon"/>
							<p className="text-white text-sm font-Montserrat font-semibold">Courses</p>
						</Link>
						<Link to="/dashboard" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard' ? '' : '' }`}>
							<img src={icon} alt="icon"/>
							<p className="text-white text-sm font-Montserrat font-semibold">Forum</p>
						</Link>
						<Link to="/dashboard" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard' ? '' : '' }`}>
							<img src={icon2} alt="icon"/>
							<p className="text-white text-sm font-Montserrat font-semibold">Resources</p>
							<p className="text-[#344054] font-inter font-medium text-[10px] bg-white pl-[3px] pr-[3px] rounded-[10px]">coming soon</p>
						</Link>
						<Link to="/dashboard/files" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard/files' ? 'bg-[#667085]' : '' }`}>
							<img src={file} alt="icon"/>
							<p className="text-white text-sm font-Montserrat font-semibold">My Files</p>
						</Link>
						<Link to="/dashboard/settings" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard/settings' ? 'bg-[#667085]' : '' }`}>
							<img src={settings} alt="icon"/>
							<p className="text-white text-sm font-Montserrat font-semibold">Settings</p>
						</Link>
					</ul>
				</div>
				<div className='flex justify-center items-center gap-3'>
					<img className="w-10 h-10 rounded-full" src={image} alt="pic"/>
					<div className="">
						<p className="text-white font-semibold font-inter text-sm">John Doe</p>
						<p className="text-[#E3EFFC] text-xs font-inter">johndoe@gmail.com</p>
					</div>
					<img src={signout} alt="logout"/>
				</div>
			</div>
			<motion.div
			    animate={closeMobile ? "open" : "closed"}
                variants={variants} 
			    className="md:hidden bg-[#08132A] flex flex-col justify-between pb-3 min-h-screen absolute left-0 bottom-0 top-[8.5rem] z-50 "
			>
				<ul className="flex flex-col items-start justify-center gap-3 mt-8 ">
					<Link onClick={handleCloseMobile} to="/dashboard" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard' ? 'bg-[#667085]' : '' }`}>
						<img src={icon1} alt="icon"/>
						<p className="text-white text-sm font-Montserrat font-semibold">Home</p>
					</Link>
					<Link onClick={handleCloseMobile} to="/dashboard/courses" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard/courses' ? 'bg-[#667085]' : '' }`}>
						<img src={book} alt="icon"/>
						<p className="text-white text-sm font-Montserrat font-semibold">Courses</p>
					</Link>
					<Link onClick={handleCloseMobile} to="/dashboard" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard' ? '' : '' }`}>
						<img src={icon} alt="icon"/>
						<p className="text-white text-sm font-Montserrat font-semibold">Forum</p>
					</Link>
					<Link onClick={handleCloseMobile} to="/dashboard" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard' ? '' : '' }`}>
						<img src={icon2} alt="icon"/>
						<p className="text-white text-sm font-Montserrat font-semibold">Resources</p>
						<p className="text-[#344054] font-inter font-medium text-[10px] bg-white pl-[3px] pr-[3px] rounded-[10px]">coming soon</p>
					</Link>
					<Link onClick={handleCloseMobile} to="/dashboard/files" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard/files' ? 'bg-[#667085]' : '' }`}>
						<img src={file} alt="icon"/>
						<p className="text-white text-sm font-Montserrat font-semibold">My Files</p>
					</Link>
					<Link onClick={handleCloseMobile} to="/dashboard/settings" className={`flex items-center gap-4 rounded-[4px] p-2 pl-3 w-full ${pathname === '/dashboard/settings' ? 'bg-[#667085]' : '' }`}>
						<img src={settings} alt="icon"/>
						<p className="text-white text-sm font-Montserrat font-semibold">Settings</p>
					</Link>
				</ul>
				<div className='flex justify-center items-center gap-3 p-3'>
					<img className="w-10 h-10 rounded-full" src={image} alt="pic"/>
					<div className="">
						<p className="text-white font-semibold font-inter text-sm">John Doe</p>
						<p className="text-[#E3EFFC] text-xs font-inter">johndoe@gmail.com</p>
					</div>
					<img src={signout} alt="logout"/>
				</div>
			</motion.div>
		</>
	)
}

export default Sidebar