import {createSlice} from '@reduxjs/toolkit';

const questionSlice = createSlice({
	name: "question",
	initialState: {
		question: [],
		openModal: false,
		grade: 0
	},
	reducers:{
		addQuestion(state,action){
			const newItem = action.payload;
			state.question = newItem;
		},
		setOpenModalTrue(state){
			state.openModal = true;
		},
		setOpenModalFalse(state){
			state.openModal = false;
		},
		setGrade(state,action){
			const newGrade = action.payload;
			state.grade = newGrade;
		}
	}
})

export const questionActions = questionSlice.actions;
export default questionSlice;