import SearchForm from "./SearchForm";
import ForumPost from "./ForumPost";
import TypeMessage from "./TypeMessage";
import { useLocation } from "react-router-dom";
import Modal from "./Modal";
import {useSelector} from 'react-redux';

const ForumChatArea = () => {
  const openModal = useSelector((state) => state.forum.openModal)
  const location = useLocation();
  const postId = location.state?.postId ? location.state?.postId : "";

  return (
    <section
      className={`w-full  ${
        postId ? "lg:w-[70%]" : "lg:w-full"
      } flex flex-col items-center `}
    >
      <div className="w-full mt-10  lg:m-0 h-full lg:w-full flex flex-col items-center  justify-between">
        <div className="w-full lg:h-[85vh] h-[85vh] overflow-y-scroll lg:px-8 lg:pb-4 px-4 pb-4 lg:w-full flex flex-col items-center">
          <div className="w-full flex sticky top-0 left-0 bg-white  flex-col gap-4 lg:justify-between items-center lg:flex-row">
            <div className="w-[90%] md:w-[70%] lg:w-[40%] my-4 lg:order-1">
              <SearchForm />
            </div>
            <div className="w-full lg:w-fit px-6 ">
              <h4 className="font-semibold text-[1.5rem] text-left">
                Sentences
              </h4>
              <p className="text-blue">203 Members</p>
            </div>
          </div>
          <div className="w-full flex flex-col items-center p-4">
            <p className="px-4 py-2 bg-gray-200 rounded-md w-fit">20:15 pm</p>
            <p className="w-[90%] lg:w-fit text-center">
              You created this thread today. Every conversation regarding this
              subject will be made here.
            </p>
          </div>
          <div className="w-full p-4 flex flex-col gap-6">
            <ForumPost />
            <ForumPost />
            <ForumPost />
          </div>
        </div>
        <div className="w-full  lg:px-8 lg:py-4 p-4">
          <TypeMessage />
        </div>
      </div>
      {openModal && (<Modal/>)}
    </section>
  );
};

export default ForumChatArea;
