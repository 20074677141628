import React from 'react';
import Sidebar from '../../components/dashboard/Sidebar'
import { useLocation, Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';
//import useAuth from "../../hooks/useAuth";

const Dashboard = () => {
	//const { auth } = useAuth();
	const location = useLocation();
	const access = useSelector(state => state.auth.auth)
	console.log(access)
	return (
		<div className="flex w-screen overflow-hidden bg-[#F2F4F7]">
			<Sidebar/>
			<div className="w-full">
				{!access ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />}
			</div>
		</div>
	)
}

export default Dashboard;